import React, {useMemo} from 'react'

// @mui/material
import {Box, Button, Typography} from '@mui/material'
// @mui/icons-material
import {Instagram as InstagramIcon} from '@mui/icons-material/'

// imagekitio-react
import {IKImage} from 'imagekitio-react'

// styles
import styles from './styles.module.css'

function Instagramer({id, image, name, link}) {
  const fr = useMemo(() => {
    if (name.length <= 7) return 0.7
    if (name.length <= 12) return 1
    return 2
  }, [name])

  return (
    <Button
      component="a"
      href={link}
      target="blank"
      key={id}
      rel="external"
      className={styles.button}
      variant="outline"
      sx={{
        margin: '0 0 3em',
        outline: 0,
        width: '100%',
        height: 'auto',
        padding: 0,
        display: 'block',
        border: 0,
        overflow: 'visible',
        textTransform: 'none',
        '@media (max-width: 767px)': {
          margin: '0 0 1.25em',
        },
        ':hover': {
          '.instagramer': {
            gridTemplateColumns: `1fr ${fr}fr`,
          },
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: 'auto',
          minHeight: '300px',
          background:
            'linear-gradient(180deg,rgba(2,8,33,0.25) 0%,#020821 100%)',
          width: '100%',
          display: 'flex',
        }}
      >
        <Box
          className="shader"
          sx={{
            transition: 'all 500ms ease',
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            ':hover': {
              background: 'rgba(2,8,33,0.45)',
            },
          }}
        >
          <Box
            className={`instagramer ${styles.instagramer}`}
            sx={{
              /* display: 'flex', */
              zIndex: 3,
              width: '100%',
              /* alignItems: 'center', */
              position: 'absolute',
              bottom: '1.25rem',
              gap: '15px',
              transition: 'grid-template-columns 500ms',
              display: 'grid',
              gridTemplateColumns: '1fr 0fr',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <InstagramIcon
                sx={{
                  display: 'inline-block',
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  fontWeight: '400',
                  lineHeight: '1em',
                  marginRight: '0.2em',
                  speak: 'none',
                  textAlign: 'center',
                  textDecoration: 'inherit',
                  textTransform: 'none',
                  width: '1em',
                  color: '#fff',
                }}
              />
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Typography variant="body1" component="p" textAlign="left">
                {name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <IKImage
          urlEndpoint={'https://ik.imagekit.io/tx6beroitnm'}
          src={image}
          alt={name}
          lqip={{active: true, quality: 2, blur: 10}}
          loading="lazy"
          height="auto"
          width="100%"
          style={{objectFit: 'cover'}}
        />
      </Box>
    </Button>
  )
}

export default Instagramer

import React from 'react'
import {IKImage} from 'imagekitio-react'
import {Box, Typography, useTheme, Link} from '@mui/material'

// @emotion/css
import {css} from '@emotion/css'

// CSS Component CarouselItemArrows
import Patron from '../../assets/images/apatron2.svg'

import ButtonStyled from '../ButtonStyled/ButtonStyled'

const CarouselItemArrows = ({text, image, children, backgroundColor}) => {
  const theme = useTheme()

  return (
    <Box
      className="section-container"
      sx={{
        backgroundColor: backgroundColor || theme.palette.primary.light,
        position: 'relative',
      }}
    >
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${Patron})`,
          position: 'absolute',
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          bottom: 0,
        }}
      />
      <div className="row">
        <Box
          className="container "
          sx={{
            display: 'flex',
            flexDirection: 'row',

            '@media (max-width: 599px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Box
            className="car-item-arrows__left"
            sx={{
              flex: ' 0 0 52%',

              display: 'flex',
              alignItems: 'center',

              '@media (min-width: 992px)': {
                height: '700px',
              },
              '@media (min-width: 768px)': {
                maxWidth: '50%',
              },
              '@media (max-width: 576px)': {
                height: '340px',
                flex: 'none',
                width: '100%',
              },
            }}
          >
            <Box
              className="car-item-arrows__left-img-container"
              sx={{
                borderRadius: '10px',
                width: '100%',
                height: '100%',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: {
                  md: 'relative',
                  xs: 'relative',
                },
                '@media (max-width: 1000px)': {
                  borderRadius: '0px',
                },
                '@media (max-width: 599px)': {},
              }}
            >
              <IKImage
                className="article-image"
                alt={text}
                urlEndpoint={'https://ik.imagekit.io/tx6beroitnm'}
                src={`${image}?tr=w-640,h-640w,q-80`}
                lqip={{active: true}}
                loading="lazy"
                height="100%"
                width="100%"
              />
            </Box>
          </Box>
          <Box
            className="car-item-arrows__right"
            sx={{
              flex: ' 0 0 52%',
              display: 'flex',
              alignItems: 'center',
              pl: '3rem',
              zIndex: 20,

              '@media (min-width: 992px)': {},
              '@media (min-width: 768px)': {
                flex: ' 0 0 52%',

                display: 'flex',
                maxWidth: '50%',
              },
              '@media (max-width: 576px)': {
                width: '100%',
                flex: '0',
                pt: '3rem',
                px: '1rem',
              },
            }}
          >
            <Box
              className="car-item-arrows__right-text-container"
              sx={{
                mx: 'auto',
                '@media (max-width: 599px)': {
                  textAlign: 'center',
                },
              }}
            >
              <Typography
                variant="h2"
                className="text-gap"
                mb={3}
                sx={{color: theme.palette.text.alter}}
              >
                {text.Title}
              </Typography>

              <Typography
                variant="body1"
                className="text-gap"
                sx={{color: theme.palette.text.alter}}
              >
                {text.TextBody}
              </Typography>
              {!children ? (
                <Link
                  className={css({color: 'inherit', textDecoration: 'none'})}
                  target="_blank"
                  underline="none"
                  href={text.LinkButton}
                >
                  <ButtonStyled text={text.TextButton} />
                </Link>
              ) : (
                children
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </Box>
  )
}

export default CarouselItemArrows

import React, {useEffect} from 'react'
// prop types
import PropTypes from 'prop-types'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {IKImage} from 'imagekitio-react'

// @mui components
import {Box} from '@mui/material'

const Hero = props => {
  const {children, sx, src, style, background} = props
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#hero',
        start: 'top top',
        end: '75% top',
        scrub: true,
      },
    })

    gsap.utils.toArray('.parallax').forEach(layer => {
      const depth = layer.dataset.depth
      const movement = -(layer.offsetHeight * depth)
      tl.to(layer, {y: movement, ease: 'none' /* opacity: 0.5  */}, 0)
    })

    return ScrollTrigger.removeEventListener('refresh', () =>
      ScrollTrigger.refresh(),
    )
  }, [])

  return (
    <Box
      id="hero"
      className="hero"
      sx={{
        minHeight: '100vh',
        backgroundColor: background,
        ...style,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: '1',
          height: '100%',
          width: '100%',
          '&::before': {
            content: '""',
            position: 'absolute',
            background: 'linear-gradient(180deg,rgba(0,0,0,0) 0%,#000000 60%)',
            opacity: ' 0.55',
            zIndex: 1,
            height: '100%',
            width: '100%',
          },
          ...sx,
        }}
      >
        <IKImage
          urlEndpoint={'https://ik.imagekit.io/tx6beroitnm'}
          src={`${src}?tr=w-${window.innerWidth},h-${window.innerHeight},q-90`}
          lqip={{active: true}}
          loading="lazy"
          alt="hero"
          width={window.innerWidth}
          height={'100%'}
          style={{
            position: 'relative',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          position: 'relative',
          zIndex: 200,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

Hero.defaultProps = {
  sx: {},
}

Hero.propTypes = {
  sx: PropTypes.object,
}

export default Hero

/* {src ? (
  <IKImage
    urlEndpoint={'https://ik.imagekit.io/tx6beroitnm'}
    src={`${src}?tr=w-${window.innerWidth},h-${900},q-90`}
    lqip={{active: true, quality: 20, blur: 10}}
    alt="hero"
    width={window.innerWidth}
    height={'100%'}
    style={{
      position: 'relative',
      objectFit: 'cover',
    }}
  />
) : (
  <IKImage
    urlEndpoint={'https://ik.imagekit.io/tx6beroitnm'}
    lqip={{active: true, quality: 20, blur: 10}}
    alt="hero"
    src={`https://ik.imagekit.io/tx6beroitnm/descubretrinidad/Header_Home_mtHJ2I8dS.jpg?tr=w-${
      window.innerWidth
    },h-${900},q-90`}
    width={window.innerWidth}
    height={'100%'}
    style={{
      position: 'relative',
      objectFit: 'cover',
    }}
  />
)} */

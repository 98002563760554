/* eslint-disable react/function-component-definition */
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {IKImage} from 'imagekitio-react'

// @mui/material
import {Typography} from '@mui/material'

// components
import ButtonStyled from '../../components/ButtonStyled/ButtonStyled'

// contexts
import {useLanguage} from '../../context/LanguageProvider'

// styles
import styles from './styles.module.css'

const NotFound = () => {
  const {languageState} = useLanguage()

  const navigate = useNavigate()

  useEffect(() => {
    const handleNavigation = () => {
      // Reset state or perform necessary actions here
    }

    window.addEventListener('popstate', handleNavigation)

    return () => {
      window.removeEventListener('popstate', handleNavigation)
    }
  }, [navigate])

  return (
    <div className={styles.main}>
      <IKImage
        urlEndpoint={'https://ik.imagekit.io/tx6beroitnm'}
        src={`https://ik.imagekit.io/tx6beroitnm/Valle/manaca_iznaga_jf_uupuWb.jpg?tr=q-80`}
        lqip={{active: true, quality: 20, blur: 10}}
        loading="lazy"
        alt="Descubre Trinidad"
        width={'100%'}
        height={'100%'}
        className={styles.img}
      />
      <div className={`${styles.content} appear`}>
        <Typography variant="h2" sx={{textAlign: 'center'}}>
          Descubre Trinidad
        </Typography>
        <Typography variant="h5" sx={{textAlign: 'center'}}>
          {languageState.texts.NotFound.Title}
        </Typography>
        <a href="/">
          <ButtonStyled text={languageState.texts.NotFound.GoHome} />
        </a>
      </div>
    </div>
  )
}

export default NotFound

// prop-types
import React from 'react'
import PropTypes from 'prop-types'
import 'react-lazy-load-image-component/src/effects/blur.css'
import {LazyLoadImage, trackWindowScroll} from 'react-lazy-load-image-component'

// images
import Crash from '../../assets/images/crash.webp'

// utils
import {parseImageKit} from '../../utils/functions'

const Image = props => {
  const {url, width, height, className, style, scrollPosition} = props
  return (
    <LazyLoadImage
      className={className}
      src={url ? url : Crash}
      width={width}
      height={height}
      PlaceholderSrc={url ? parseImageKit(url, '1') : Crash}
      scrollPosition={scrollPosition}
      effect="blur"
      {...style}
    />
  )
}

Image.defaultProps = {
  className: {},
  style: {},
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.objectOf(PropTypes.string),
  style: PropTypes.objectOf(PropTypes.string),
}

export default trackWindowScroll(Image)

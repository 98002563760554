import React, {useEffect} from 'react'

// react-router-dom
import {Link} from 'react-router-dom'

// prop types
import PropTypes from 'prop-types'

// @mui components
import {useTheme} from "@mui/material/styles"
import { Typography, Button, IconButton} from '@mui/material'

// @mui icons
import {Close as CloseIcon, ArrowBack as ArrowBackIcon} from '@mui/icons-material/'

// own components
import Container from '../Container/Container'

import './secondary-drawer.css'

// contexts

import {useRoute} from '../../context/RouterProvider'
import useScreenSize from '../../hooks/useScreenSize'
import SecondaryLink from './SecondaryLink/SecondaryLink'

const SecondaryNavigationDrawer = props => {
  const {visible, onClose, menuSecondary, setShowSecondaryDrawer} = props

  const {routeState, setRouteState} = useRoute()

  const theme = useTheme()

  const {width} = useScreenSize()

  const setMenuDrawer = () => {
    setShowSecondaryDrawer(false)
  }

  useEffect(() => {}, [routeState.route])

  const clickJtem = e => {
    const {id} = e.target
    setRouteState({type: 'jndex', to: Number(id.substring(1))})

    onClose(e)
  }

  return (
    <Container
      sx={{
        top: 0,
        position: 'fixed',
        transform: 'translate(500px)',
        zIndex: 1300,
        transition: 'all 200ms linear',
        width: visible ? '500px' : 0,
        minHeight: '100vh',
        background:
          width < 1000
            ? theme.palette.primary.light
            : theme.palette.primary.main,
        flexDirection: 'column',
        '@media(max-width:1000px)': {
          transform: 'translate(0)',
          width: visible ? '100vw' : 0,
        },
      }}
    >
      <Container
        sx={{
          width: '100%',
          padding: '20px',
          py: '2vh',
          background: width < 1000 && theme.palette.primary.main,
          display: visible && width < 1000 ? 'flex' : 'none',
        }}
        justify="space-between"
      >
        <Button
          className="item-button"
          variant="outline"
          onClick={setMenuDrawer}
          sx={{
            textTransform: 'capitalize',
            transitionProperty: 'all',
            transitionDuration: ' 0.35s',
            transitionTimingFunction: 'ease',
            padding: '1rem 0',
            transitionDelay: '0s',
            boxShadow: 'none',
            '& .icon-back-button': {
              transform: 'translateX(0)',
              transitionProperty: 'all',
              transitionDuration: ' 0.35s',
              transitionTimingFunction: 'ease',
              transitionDelay: '0s',
            },
            '&:hover': {
              boxShadow: 'none',
              '& .icon-back-button': {
                transform: 'translateX(-0.25em)',
                transitionProperty: 'all',
                transitionDuration: ' 0.35s',
                transitionTimingFunction: 'ease',
                transitionDelay: '0s',
              },
            },
          }}
        >
          <ArrowBackIcon
            className="icon-back-button"
            fontSize="medium"
            sx={{paddingRight: '0.5rem', color: theme.palette.text.alter}}
          />
          <Typography
            variant="body1"
            sx={{
              marginBottom: 0,
              lineHeight: '115%' /* mr: 1, */,
              display: 'inline-block',
              color: theme.palette.text.alter,
            }}
          >
            Menu Principal
          </Typography>
        </Button>
        <IconButton
          color="side"
          aria-label="close drawer"
          sx={{color: theme.palette.text.alter}}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Container>

      <Link
        id={menuSecondary.id}
        style={{
          textDecoration: 'none',
          marginTop: width > 1000 ? '110px' : '2rem',
          marginBottom: '1rem',
          color:
            menuSecondary.index === routeState.route
              ? theme.palette.primary.main
              : theme.palette.text.primary,
        }}
        onClick={onClose}
        to={menuSecondary.route ? menuSecondary.route : 'Hola'}
      >
        <Typography
          id={`b${menuSecondary.id}`}
          sx={{
            textTransform: 'none',
            marginTop: '20px',
            paddingLeft: '20px',
            opacity: visible ? 1 : 0,
            transition: 'opacity 1000ms ease',
            zIndex: visible ? 1300 : -1,
            display: visible ? 'initial' : 'none',
            color: theme.palette.text.alter,
            '&:hover': {
              color: theme.palette.text.secondary,
            },
          }}
          variant="body1"
        >
          {menuSecondary.label}
        </Typography>
      </Link>

      {visible && menuSecondary.menu && (
        <Container direction="column">
          {menuSecondary.menu
            .filter(jtem => jtem.label)
            .map((jtem, j) => (
              <SecondaryLink
                key={jtem.route}
                jtem={jtem}
                j={j}
                onClick={clickJtem}
              />
            ))}
        </Container>
      )}
    </Container>
  )
}

SecondaryNavigationDrawer.defaultProps = {
  menuSecondary: {},
}

SecondaryNavigationDrawer.propTypes = {
  menuSecondary: PropTypes.any,
}

export default SecondaryNavigationDrawer

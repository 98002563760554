import React from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

const LoadingContext = React.createContext()

const LoadingProvider = ({children}) => {
  const [loading, setLoading] = React.useState(false)
  const value = {loading, setLoading}
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

LoadingContext.propTypes = {
  children: PropTypes.node.isRequired,
}

// hooks
const useLoading = () => {
  const context = React.useContext(LoadingContext)
  if (context === undefined)
    throw new Error('modeContext must be used within a Provider')
  return context
}

export {LoadingProvider, useLoading}

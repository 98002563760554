import React, {useEffect, useState, useRef, useCallback} from 'react'
import {css} from '@emotion/css'

// react-router-dom
import {Link} from 'react-router-dom'

// @mui components
import {useTheme} from '@mui/material/styles'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Divider,
  Typography,
} from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Slide from '@mui/material/Slide'

import PropTypes from 'prop-types'

// @mui icons
import {Menu as MenuIcon, Search as SearchIcon} from '@mui/icons-material/'

// contexts
import {useLanguage} from '../../context/LanguageProvider'

// images
import logo from '../../assets/images/logos/logo-new.svg'

// own components
import Container from '../Container/Container'
import NavigationDrawer from '../NavigationDrawer/NavigationDrawer'
import SecondaryNavigationDrawer from '../SecondaryNavigationDrawer/SecondaryNavigationDrawer'

import SearchModal from './SearchModal/SearchModal'
import useScreenSize from '../../hooks/useScreenSize'
import SelectLanguage from '../SelectLanguage/SelectLanguage'
import {useAsync} from '../../hooks/hooks'
import config from '../../config'
function HideOnScroll(props) {
  const {children, window} = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const Navbar = props => {
  const {languageState} = useLanguage()
  const theme = useTheme()
  const [activeLink, setActiveLink] = useState(
    languageState.texts.Navbar.Links[0],
  )

  const [showSearch, setShowSearch] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [menu, setMenu] = useState(languageState.texts.Navbar.Links)
  const [showSecondaryDrawer, setShowSecondaryDrawer] = useState(false)
  const [menuSecondary, setMenuSecondary] = useState({})
  const [open] = useState(false)
  const {width} = useScreenSize()
  const [weather, setWeather] = useState(null)

  const {data, run} = useAsync()

  const anchorRef = useRef(null)

  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  useEffect(() => {
    run(
      window
        .fetch(
          `https://api.weatherapi.com/v1/current.json?key=${config?.weatherKey}&q=Sancti-Spiritus`,
        )
        .then(async response => {
          const data = await response.json()
          if (response.ok) {
            return data
          } else {
            return Promise.reject(data)
          }
        }),
    )
  }, [run])

  useEffect(() => {
    data && setWeather(data.current)
  }, [data])

  const toggleSearch = () => {
    setShowSearch(true)
  }

  const onCloseSearch = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setShowSearch(false)
  }

  const toggleDrawer = e => {
    let {target} = e
    if (target.nodeName === 'path' || target.nodeName === 'svg')
      while (target.nodeName.toLowerCase() !== 'button')
        target = target.parentNode

    if (target.id !== 'menu') {
      // eslint-disable-next-line array-callback-return
      languageState.texts.Navbar.Links.filter(item => item.label).map(item => {
        if (target.id === item.id) {
          setActiveLink(item)
          setMenu(item.menu)
          setShowDrawer(true)
        }
      })
    } else {
      setActiveLink(languageState.texts.Navbar.Links[0])
      setMenu(languageState.texts.Navbar.Links)
      setShowDrawer(true)
    }
  }

  const toggleSecondaryDrawer = value => {
    setShowSecondaryDrawer(value)
  }

  const onCloseDrawer = useCallback(
    event => {
      if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return
      }
      if (showSecondaryDrawer) {
        setShowSecondaryDrawer(false)
        setTimeout(() => {
          setShowDrawer(false)
        }, 201)
      } else setShowDrawer(false)
      setShowSecondaryDrawer(false)
    },
    [showSecondaryDrawer],
  )

  useEffect(() => {}, [showDrawer])

  const onEscapePress = useCallback(
    e => {
      const {keyCode} = e
      if (keyCode === 27) {
        onCloseDrawer()
        e.target.blur()
      }
    },
    [onCloseDrawer],
  )

  useEffect(() => {
    window.addEventListener('keydown', onEscapePress)
    return () => window.removeEventListener('keydown', onEscapePress)
  }, [onEscapePress])

  return (
    <header
      className={css({flexGrow: 1, width: '100vw', position: 'absolute'})}
    >
      <SearchModal visible={showSearch} onClose={onCloseSearch} />
      <NavigationDrawer
        visible={showDrawer}
        onClose={onCloseDrawer}
        activeLink={activeLink}
        toggleSecondaryDrawer={toggleSecondaryDrawer}
        setMenuSecondary={setMenuSecondary}
        setActiveLink={setActiveLink}
        menu={menu}
        setMenu={setMenu}
        weather={weather}
      />
      <SecondaryNavigationDrawer
        visible={showSecondaryDrawer}
        menuSecondary={menuSecondary}
        setShowSecondaryDrawer={setShowSecondaryDrawer}
        activeLink={activeLink}
        setMenu={setMenu}
        setActiveLink={setActiveLink}
        onClose={onCloseDrawer}
      />

      <HideOnScroll {...props}>
        <AppBar
          className="navbar"
          sx={{
            transition: 'all 500ms ease',
            padding: '0.5rem 0',
            opacity: showSearch ? 0 : 1,
          }}
          elevation={0}
          position="fixed"
          color="primary"
        >
          <Box
            className="navbar-container"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer}
                id="menu"
                sx={{
                  display: width >= 1200 ? 'none' : 'flex',
                  alignItems: 'center',
                  width: '40px',
                  height: '40px',
                }}
              >
                <MenuIcon
                  sx={{
                    color: theme.palette.text.alter,
                    ':hover': {color: theme.palette.text.main},
                  }}
                  id="menu"
                />
              </IconButton>
              <Link id="home" to="/">
                <img
                  width={190}
                  height={45}
                  src={logo}
                  alt="Logo Descubre Trinidad"
                />
              </Link>
            </div>
            <nav
              className={css({
                display: 'flex',
                alignItems: 'center',
              })}
            >
              <Box
                sx={{
                  display: {xs: 'none', sm: 'flex'},
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: width < 600 ? 'none' : 'flex',
                    alignItems: 'center',
                    marginRight: '5px',
                    color: theme.palette.text.alter,
                  }}
                >
                  <img
                    loading="lazy"
                    width="50"
                    src={weather?.condition?.icon}
                    alt=""
                    style={{
                      filter:
                        'drop-shadow(0px 0px 0 grey) drop-shadow(0px 0px 0 grey)',
                      display: width < 600 ? 'none' : 'flex',
                    }}
                  />
                  {weather?.temp_c}°C
                </Typography>
                <Divider
                  sx={{
                    border: '1px solid',
                    height: '25px',
                    margin: 'auto 10px auto 5px',
                    color: `${theme.palette.text.alter}61`,
                    display: width < 1300 ? 'none' : 'flex',
                  }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <SelectLanguage />
                <Divider
                  sx={{
                    border: '1px solid',
                    height: '25px',
                    margin: 'auto 5px',
                    color: `${theme.palette.text.alter}61`,
                  }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <Box sx={{display: width < 1200 ? 'none' : 'flex'}}>
                  {languageState.texts.Navbar.Links.filter(
                    item => item.label,
                  ).map(item => (
                    <Container key={item.id} align="center">
                      <Button
                        id={item.id}
                        onClick={toggleDrawer}
                        color="text"
                        sx={{
                          textTransform: 'none',
                          fontWeight: 500,
                          color: theme.palette.text.alter,
                          ':hover': {
                            color: theme.palette.text.main,
                          },
                        }}
                        size="medium"
                        ref={item.menu ? anchorRef : null}
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                      >
                        {item.label}
                      </Button>
                      <Divider
                        sx={{
                          border: '1px solid',
                          height: '25px',
                          margin: 'auto 5px',
                          color: `${theme.palette.text.alter}61`,
                        }}
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                    </Container>
                  ))}
                </Box>
              </Box>
              <Button
                sx={{
                  borderRadius: '10px',
                  height: '40px',
                  width: '40px',
                  marginRight: '0',
                  minWidth: 0,
                }}
                color="side"
                aria-label="search"
                onClick={toggleSearch}
              >
                <SearchIcon
                  sx={{
                    color: theme.palette.text.alter,
                    ':hover': {
                      color: theme.palette.text.main,
                    },
                  }}
                  fontSize="large"
                />
              </Button>
            </nav>
          </Box>
        </AppBar>
      </HideOnScroll>
    </header>
  )
}

export default Navbar

// https://api.open-meteo.com/v1/forecast?latitude=21.97&longitude=-79.44

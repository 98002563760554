import React from 'react'

// @emotion/css
import {css} from '@emotion/css'

// @mui/material
import {useTheme} from '@mui/material/styles'

const Empty = props => {
  const {text, color} = props

  const theme = useTheme()

  return (
    <div className="w-full h-full p-error flex flex-col items-center justify-center gap-10">
      <p
        className={`perror text-center ${css({
          color: color ? color : theme.palette.text.alter,
        })}`}
      >
        {text}
      </p>
    </div>
  )
}

export default Empty

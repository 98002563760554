/* eslint-disable react/function-component-definition */
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

// @mui/material
import {useTheme} from '@mui/material/styles'
import {Box, Typography, Link as MuiLink} from '@mui/material'

import {gsap} from 'gsap'

// contexts
import {useLanguage} from '../../context/LanguageProvider'

/* import InstagramIcon from '@mui/icons-material/Instagram' */
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import Instagramer from './Instagramer/Instagramer'
import {Launch} from '@mui/icons-material'

// Import imagenes

const ShowGrid = () => {
  const {languageState} = useLanguage()
  const theme = useTheme()
  gsap.registerPlugin(ScrollTrigger)

  const location = useLocation()

  const [instagramers, setInstagramers] = useState({})

  useEffect(() => {
    const {pathname} = location
    const split = pathname.split('/')
    const text = split[split.length - 1]
    let loca = languageState.texts.Home.ShowHover.Experiences.findIndex(
      exp => exp.id === text,
    )

    if (text === 'craft') loca = 2
    if (text === 'museums') loca = 0
    if (languageState.texts.Home.ShowGrid.Instagramers[loca])
      setInstagramers(languageState.texts.Home.ShowGrid.Instagramers[loca])
    else setInstagramers(languageState.texts.Home.ShowGrid.Instagramers['4'])
  }, [location, languageState])

  return (
    <Box className="row" sx={{backgroundColor: theme.palette.primary.main}}>
      <Box id="container" className="showgrid container">
        <Box
          className="showgrid-row"
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            position: 'relative',
            boxSizing: 'border-box',
            '@media (max-width: 767px)': {
              flexDirection: 'column-reverse',
              py: 5,
            },
          }}
        >
          <Box
            className="showgrid-left-container"
            sx={{
              flex: '0 0 58.33333%',
              maxWidth: '58.33333%',
              flexWrap: 'wrap',
              py: '8rem',
              columnGap: '3em',
              columnCount: 2,

              '@media (max-width: 991.98px)': {
                columnCount: 1,
                flex: '0 0 41.66667%',
                maxWidth: '41.66667%',
              },
              '@media (max-width: 767px)': {
                flex: '0 0 100%',
                maxWidth: '100%',
                columnCount: 2,
                p: 0,
                columnGap: '1.25em',
                paddingRight: '15px',
                paddingLeft: '15px',
              },
              '@media (max-width:400px)': {
                columnCount: 1,
              },
            }}
          >
            <Box
              className="showgrid-imgs-container"
              sx={{
                display: 'grid',
                gridAutoRows: '0.5fr',

                boxSizing: 'border-box',
              }}
            >
              {instagramers.list
                ? instagramers.list?.map((item, i) => (
                    <Instagramer key={i} {...item} />
                  ))
                : null}
            </Box>
          </Box>

          <Box
            className="showgrid-right-container"
            sx={{
              flex: '0 0 42.66667%',
              width: '42.66667%',
              height: 'auto',
              inset: '0px 0px 0px 0px',
              position: 'absolute',
              top: '100px',
              '@media (max-width: 991.98px)': {
                flex: '0 0 58.33333%',
                maxWidth: '58.33333%',
              },
              '@media (max-width: 768px)': {
                flex: '0 0 58.33333%',
                maxWidth: '100%',
                px: '15px',
              },
              '@media (max-width: 767px)': {
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                top: 0,
              },
              '@media (max-width: 575px)': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '83.33333%',
                mx: 'auto',
                px: 0,
              },
            }}
          >
            <Box
              className="text-sticky-container"
              sx={{
                paddingRight: '14%',
                pt: '5%',
                pb: '8rem',
                top: 0,
                bottom: 0,
                position: 'sticky',
                flex: '0 0 100%',
                '@media (max-width: 991.98px)': {
                  p: '160px 15px 80px 0px',
                },
                '@media (max-width: 768px)': {
                  p: '0px 0px 2rem 0px',
                },
              }}
            >
              <Typography variant="h4" sx={{color: theme.palette.text.alter}}>
                {languageState.texts.Home.ShowGrid.Title}
              </Typography>

              <Typography
                variant="subtitle1"
                sx={{my: 3, color: theme.palette.text.alter}}
              >
                {languageState.texts.Home.ShowGrid.TextBody}
              </Typography>
              <Box
                className="footer-text"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '@media (max-width: 767px)': {
                    display: 'flex',

                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  '@media (max-width: 575px)': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                }}
              >
                <MuiLink
                  id="instagram-link"
                  target="_blank"
                  underline="none"
                  href="https://www.instagram.com/descubre_trinidad/"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    ':hover': {
                      span: {
                        textDecoration: 'underline !important',
                      },
                      textDecoration: 'underline !important',
                    },
                  }}
                >
                  <Typography
                    variant="subtitle6"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 700,
                      color: theme.palette.text.alter,
                    }}
                  >
                    {languageState.texts.Home.ShowGrid.Textfooter}
                  </Typography>
                  <Launch
                    className="arrow-signal"
                    sx={{
                      fontSize: '16px',
                      mr: 1,
                      color: theme.palette.text.alter,
                    }}
                  />
                </MuiLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ShowGrid

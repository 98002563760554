import React from 'react'
import {forwardRef} from 'react'

import PropTypes from 'prop-types'

// @mui/material
import {Typography, Button} from '@mui/material'

const ButtonStyled = forwardRef((props, ref) => {
  const {className, text, color, sx, type, other} = props

  return (
    <Button
      type={type}
      className={className ? className : ''}
      ref={ref}
      variant="contained"
      color={color}
      sx={{
        margin: 0,
        borderRadius: '999rem',
        textTransform: 'none',
        transitionProperty: 'all',
        transitionDuration: ' 0.25s',
        transitionTimingFunction: 'ease-in',
        transitionDelay: '0s',
        pointerEvents: 'all',
        boxShadow: 'none',
        p: 0,

        '& .icon-button': {
          transform: 'translateX(0)',
          transitionProperty: 'all',
          transitionDelay: '0s',
        },
        '&:hover': {
          boxShadow: 'none',
          color: '#ffffff',
          '& .icon-button': {
            transform: 'translateX(0.25em)',
            transitionProperty: 'all',
            transitionDelay: '0s',
          },
        },
        ...sx,
      }}
      {...other}
    >
      <Typography
        variant="subtitle1"
        sx={{
          position: 'relative',
          lineHeight: '115%',
          cursor: 'pointer',
          fontWeight: 500,
          /* paddingRight: '3rem', */
          padding: '1rem 1rem',
          color: 'inherit',
          textDecoration: 'none',
          '@media (max-width: 599px)': {
            padding: '0.8rem',
          },
        }}
      >
        {text}
      </Typography>
    </Button>
  )
})

ButtonStyled.displayName = 'ButtonStyled'
ButtonStyled.defaultProps = {
  text: 'Leer Más',
  color: 'buttonligth',
}

ButtonStyled.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
}

export default ButtonStyled

import React from 'react'

// @mui/material
import {useTheme} from '@mui/material/styles'
import {Typography} from '@mui/material'

// own components
import Container from '../../Container/Container'

// context
import {useLanguage} from '../../../context/LanguageProvider'

const CopyrightRow = () => {
  const {languageState} = useLanguage()
  const theme = useTheme()

  return (
    <Container
      sx={{
        width: '100%',
        background: theme.palette.secondary.special,
        padding: '20px',
      }}
      justify="center"
    >
      <Typography
        variant="subtitle1"
        align="center"
        sx={{color: theme.palette.text.alter}}
      >
        {languageState.texts.Footer.Copyright}
      </Typography>
    </Container>
  )
}

export default CopyrightRow

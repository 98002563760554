import React, {lazy, useMemo, useCallback, useEffect, useState} from 'react'
import {useDebounce} from 'use-lodash-debounce'

// prop types
import PropTypes from 'prop-types'

// @mui components
import {useTheme} from '@mui/material/styles'
import {
  Box,
  Drawer,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  Divider,
} from '@mui/material'

// @mui icons
import {Close, Search} from '@mui/icons-material'

// own components
import Empty from '../../Empty/Empty'
import Loading from '../../Loading/Loading'
import Container from '../../Container/Container'

// services
import {searchNew} from '../../../services/post'

// context
import {useLanguage} from '../../../context/LanguageProvider'

// lazy components
const GridCard = lazy(() => import('../../Grid/GridCard/GridCard'))

const SearchModal = props => {
  const {visible, onClose} = props

  const [resultList, setResultList] = useState([])

  const {languageState} = useLanguage()
  const theme = useTheme()

  const [visibleState, setVisibleState] = useState(false)
  const [toSearch, setToSearch] = useState('')

  const handleInput = e => {
    const {value} = e.target
    setToSearch(value)
  }

  const escapeHandler = useCallback(e => {
    const {keyCode} = e
    if (keyCode === 27) {
      onClose()
      e.target.blur()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', escapeHandler)
    return () => {
      window.removeEventListener('keydown', escapeHandler)
    }
  }, [escapeHandler])

  const debounced = useDebounce(toSearch, 500)

  const [firstTime, setFirstTime] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async toSearch => {
    try {
      if (toSearch.length) {
        setFirstTime(true)
        setLoading(true)
        const response = await searchNew(
          toSearch,
          ['users', 'places', 'routes', 'events', 'news'],
          ['name', 'publicName', 'title', 'subtitle', 'description'],
          [
            'id',
            'startDate',
            'endDate',
            'calendar',
            'headerImages',
            'photo',
            'title',
            'name',
            'publicName',
            'address',
            'subtitle',
            'description',
            'type',
            'date',
          ],
        )
        const {list} = response
        setResultList(list)
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }, [])

  const printResult = useMemo(() => {
    return resultList.map((item, i) => (
      <GridCard i={i} collection={[item.type === "users" ? "place" : item.type]} {...item} key={item.id} />
    ))
  }, [resultList])

  useEffect(() => {
    fetch(debounced)
  }, [debounced])

  const [divider, setDivider] = useState(true)

  const hideDivider = () => setDivider(false)
  const showDivider = () => setDivider(true)

  const goSearch = () => {}

  useEffect(() => {
    setVisibleState(visible)
  }, [visible])

  useEffect(() => {
    if (visible)
      setTimeout(() => document.getElementById('search')?.focus(), 200)
    else document.getElementById('search')?.blur()
  }, [visible])

  return (
    <Drawer
      sx={{background: `${theme.palette.secondary.main}9c`}}
      anchor="top"
      open={visibleState}
      onClose={onClose}
    >
      <Box sx={{background: theme.palette.secondary.main}}>
        <Box className="container" sx={{marginTop: '20px'}}>
          <Container sx={{width: '100%'}} justify="flex-end">
            <IconButton
              color="side"
              aria-label="close drawer"
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Container>
          <Container sx={{width: '100%'}}>
            <FormControl
              sx={{m: 1, width: '100%'}}
              variant="standard"
              color="primary"
            >
              <InputLabel
                sx={{display: 'flex'}}
                htmlFor="standard-adornment-password"
              >
                {divider && (
                  <Divider
                    sx={{
                      border: '1px solid',
                      height: '25px',
                      margin: 'auto',
                      marginTop: '-5px',
                      marginRight: '5px',
                      color: theme.palette.disabled.light,
                    }}
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                )}
                {languageState.texts.Navbar.SearchModal.Label}
              </InputLabel>

              <Input
                id="search"
                type="text"
                value={toSearch}
                onChange={handleInput}
                onFocus={hideDivider}
                onBlur={showDivider}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="side"
                      aria-label="search"
                      onClick={goSearch}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Container>
        </Box>
        <Box
          className="container"
          sx={{
            marginTop: '20px',
            display: 'grid',
            gridTemplateRows: firstTime ? '1fr' : '0fr',
            transition: 'grid-template-rows 500ms ease',
          }}
        >
          <Box sx={{overflow: 'hidden'}}>
            {loading ? (
              <Loading circleColor={theme.palette.primary.main} />
            ) : null}

            <Box className="grid">{resultList.length ? printResult : null}</Box>
            {firstTime && !resultList.length ? (
              <Empty
                color="aliceblue"
                text={languageState.texts.Error.NoResult}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

SearchModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SearchModal

import React from 'react'

import {FormControl, Select, MenuItem} from '@mui/material'
import {useTheme} from '@mui/material/styles'

import {useLanguage} from '../../context/LanguageProvider'

export default function SelectLanguage() {
  const theme = useTheme()
  const {languageState} = useLanguage()
  const [language, setLanguage] = React.useState(
    languageState.texts.Countries[0].label,
  )

  const [open, setOpen] = React.useState(false)

  const handleChange = event => {
    setLanguage(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: 50,
        div: {
          background: 'none !important',
          borderBottom: 'none !important',
          '&::after': {borderBottom: 'none !important'},
          '&::before': {borderBottom: 'none !important'},
        },
        color: `${theme.palette.text.alter} !important`,
      }}
    >
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={language}
        label="Idioma"
        onChange={handleChange}
        sx={{
          color: `${theme.palette.text.alter} !important`,
          borderBottom: 'none !important',
          '&::after': {borderBottom: 'none !important'},
          '&::hover': {borderBottom: 'none !important', color: 'black'},
        }}
      >
        {languageState.texts.Countries.map((country, i) => (
          <MenuItem
            key={`menuItem-key${i}`}
            sx={{
              '& > img': {
                flexShrink: 1,
              },
              color: theme.palette.text.alter,
            }}
            value={country.label}
          >
            <img
              width="20"
              src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
              alt={country.label}
              style={{paddingRight: '8px'}}
            />
            {country.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-inner-declarations */

/**
 * string
 */
export const toSlug = (string)=> {
  const accents = ["á", "é", "í", "ó", "ú", "ü"];
  const vocals = ["a", "e", "i", "o", "u", "u"];
  let parsedStrings = string
    .toLowerCase()
    .trim()
    .replace(/[^\wáéíóúüñ ]+/g, "")
    .replace(/ +/g, "-");

  // transform vocals
  accents.forEach((accent, i) => {
    if (parsedStrings.indexOf(accent) > -1)
      parsedStrings = parsedStrings.replaceAll(accent, vocals[i]);
  });
  return parsedStrings;
}

const alreadyPlural = [
  'places',
  'routes',
  'events',
  'placeTypes',
  'news',
  'users',
  'texts',
  'campaigns',
  'products',
  'customers',
  'activities',
  'activityTypes',
]
export const getPlural = noun => {
  if (alreadyPlural.indexOf(noun.toLowerCase()) >= 0) return noun
  if (noun.endsWith('y')) {
    return noun.slice(0, -1) + 'ies'
  } else if (
    noun.endsWith('s') ||
    noun.endsWith('x') ||
    noun.endsWith('z') ||
    noun.endsWith('ch') ||
    noun.endsWith('sh')
  ) {
    return noun + 'es'
  } else {
    return noun + 's'
  }
}

/**
 *
 * @param {string} queries
 */
export const parseQueries = queries => {
  const toReturn = {}
  const queryParams = queries.substring(1).split('&')
  queryParams.forEach(item => {
    const [param, value] = item.split('=')
    toReturn[param] = value
  })
  return toReturn
}

/**
 * It takes a string, splits it into an array of words, capitalizes the first letter of each word, and
 * then joins the array back into a string
 * @param string - The string to be converted to sentence case.
 * @returns a string with the first letter of each word capitalized.
 */
export const toSentenceCase = string => {
  let result = ''
  let space = true
  for (let i = 0; i < string.length; i += 1) {
    if (space) {
      result += string[i].toUpperCase()
      space = false
    } else {
      result += string[i]
    }
    if (string[i] === ' ') space = true
  }
  return result
}

export function toPascalCase(str) {
  return str
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export const smartTruncate = (text, lengthText) => {
  const suffix = '...'
  if (text.length < lengthText) return text
  else {
    return (
      text.substring(
        0,
        text.substring(0, lengthText - suffix.length).lastIndexOf(' '),
      ) +
      ' ' +
      suffix
    )
  }
}

export function FormatDate({startDate, endDate}) {
  let start = new Date(startDate)
  let end = new Date(endDate)

  const options = {month: 'long'}
  let startMonth = new Intl.DateTimeFormat('es-ES', options).format(start)
  let endMonth = new Intl.DateTimeFormat('es-ES', options).format(end)

  function capitalizarPrimeraLetra(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return {
    start,
    end,
    startMonth,
    endMonth,
    capitalizarPrimeraLetra,
    toPascalCase,
    parseImageKit,
  }
}

/**
 *
 * @param {string} url
 */
export const parseImageKit = (url, quality = '80') => {
  const slashSplitter = url.split('/')
  return `${slashSplitter[0]}//${slashSplitter[2]}/${slashSplitter[3]}/tr:q-${quality}/${slashSplitter[4]}`
}

export function LazyLoadImage() {
  let lazyloadImages
  if ('IntersectionObserver' in window) {
    lazyloadImages = document.querySelectorAll('.lazy')
    let imageObserver = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var image = entry.target
            image.src = image.dataset.src
            image.classList.remove('lazy')
            imageObserver.unobserve(image)
          }
        })
      },
      {
        root: document.querySelector('#container'),
        rootMargin: '0px 0px 500px 0px',
      },
    )

    lazyloadImages.forEach(function (image) {
      imageObserver.observe(image)
    })
  } else {
    let lazyloadThrottleTimeout
    lazyloadImages = $('.lazy')
    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout)
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = $(window).scrollTop()
        lazyloadImages.each(function () {
          var el = $(this)
          if (el.offset().top < window.innerHeight + scrollTop + 500) {
            var url = el.attr('data-src')
            el.attr('src', url)
            el.removeClass('lazy')
            lazyloadImages = $('.lazy')
          }
        })
        if (lazyloadImages.length == 0) {
          $(document).off('scroll')
          $(window).off('resize')
        }
      }, 20)
    }
  }
}

/* eslint-disable no-unused-vars */
/** @jsx jsx */
import React, {Fragment, useEffect} from 'react'
import {Link} from 'react-router-dom'

import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

// @mui/material
import {useTheme} from '@mui/material/styles'
import {Box, Divider, Typography} from '@mui/material'

// @mui/material/styles
import {ArrowForward as ArrowForwardIcon} from '@mui/icons-material/'

import {jsx} from '@emotion/react'

import ButtonStyled from '../ButtonStyled/ButtonStyled'

import useArrayRef from '../../hooks/useArrayRef'
// contexts
import {useLanguage} from '../../context/LanguageProvider'

import Image from '../Image/Image'

import {css} from '@emotion/css'

let mm = gsap.matchMedia()

const ScrollSticky = () => {
  const [refs, setRef] = useArrayRef()
  gsap.registerPlugin(ScrollTrigger)
  const theme = useTheme()
  const {languageState} = useLanguage()

  const onEnter = () => {
    gsap.to('.scroll-sticky-arrow-signal', {translateX: '0.25em'})
  }

  const onLeave = () => {
    gsap.to('.scroll-sticky-arrow-signal', {translateX: '-0.25em'})
  }

  useEffect(() => {
    mm.add('(min-width: 992px)', () => {
      gsap.utils.toArray('.image').forEach((box, index) => {
        if (index > 0) {
          gsap.fromTo(
            box,
            {height: '100%'},
            {
              height: '0%',
              scrollTrigger: {
                trigger: refs.current[refs.current.length - index - 1],
                start: 'top top',
                endTrigger: refs.current[refs.current.length - (index + 1)],
                end: '100% top',
                scrub: true,
                normalizeScroll: true,
              },
            },
          )
        }
      })
      gsap.to('.image-element-parent-pin', {
        scrollTrigger: {
          trigger: '.image-element-parent-pin',
          start: 'top top',
          endTrigger: refs.current[refs.current.length - 1],
          end: 'bottom bottom',
          scrub: true,
          pin: true,
        },
      })
    })

    return ScrollTrigger.removeEventListener('update', () =>
      ScrollTrigger.update(),
    )
  }, [refs])

  return (
    <Box
      sx={{
        height: 'auto',
        position: 'relative',
      }}
    >
      <Box
        className="scroll-sticky-container"
        sx={{
          position: 'relative',
          maxWidth: '1740px',
          px: 0,
          width: '100%',
        }}
      >
        <Box
          className="field"
          sx={{
            minHeight: '100vh',
            boxSizing: 'border-box',
            '@media (min-width: 992px) and (min-width: 992px)': {
              minHeight: '100vh',
            },
            '@media (min-width: 992px)': {
              width: 'calc(100% - 40px)',
            },
          }}
        >
          {languageState.texts.Home.ScrollSticky.map((text, index) => (
            <Box
              key={`indice${index}`}
              className="field-item"
              ref={setRef}
              sx={{
                boxSizing: 'border-box',
                '@media (min-width: 992px) and (min-width: 992px)': {
                  minHeight: '90vh',
                },
              }}
            >
              <Box
                className="row"
                sx={{
                  position: 'relative',
                  mx: 0,
                  display: 'flex',
                  flexFlow: 'row-reverse wrap',
                }}
              >
                <Box
                  className="image-element-parent-pin"
                  sx={{
                    transform: 'translate(0px, 0px)',
                    margin: '0px',
                    maxWidth: '48vw',
                    width: ' 48vw',
                    padding: '0px',
                    visibility: 'visible',
                    inset: '0px 0px 0px 0px',
                    position: 'absolute',
                    /* left: 40, */
                    minHeight: '100vh',
                    [theme.breakpoints.up('md')]: {
                      mx: '0px',
                      overflow: 'hidden',
                      height: '500px',
                    },
                    '@media (max-width: 992px)': {
                      position: 'relative',
                      minHeight: '300px',
                      display: 'flex',
                      maxWidth: '100vw',
                      width: '100vw',
                      left: 0,
                    },
                  }}
                >
                  {index === 0 ? (
                    <Fragment>
                      {languageState.texts.Home.ScrollSticky.map(
                        (text, index) => (
                          <img
                            loading="lazy"
                            alt={text.Title}
                            key={`image${index}`}
                            className={`image ${
                              languageState.texts.Home.ScrollSticky.length -
                              index -
                              1
                            } lazy`}
                            src={`${
                              languageState.texts.Home.ScrollSticky[
                                languageState.texts.Home.ScrollSticky.length -
                                  index -
                                  1
                              ].src
                            }?tr=w-900,h-900,q-80`}
                            css={{
                              background:
                                'linear-gradient(180deg,rgba(2,8,33,0.25) 0%,#020821 100%)',
                              transform: 'scale(1.2, 1.2)',
                              objectFit: 'cover',
                              maxWidth: '48vw',
                              position: 'absolute',
                              width: '48vw',
                              height: '100vh',
                              top: 0,
                              overflow: 'hidden',
                              visibility: ' visible',
                              display: ' block',
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                background:
                                  'linear-gradient(180deg,rgba(0,0,0,0) 0%,#000000 100%)',
                                opacity: ' 0.30',
                                zIndex: 1,
                                width: '48vw',
                                height: '100vh',
                              },
                              '@media (max-width: 992px)': {
                                visibility: 'hidden',
                                display: 'none',
                                height: '90vh',
                              },
                            }}
                          />
                        ),
                      )}
                      <img
                        loading="lazy"
                        alt={text.Title}
                        className={`image-hidden ${index}`}
                        src={text.src}
                        css={{
                          objectFit: 'cover',
                          width: '100%',
                          position: 'relative',
                          height: '100%',
                          top: 0,
                          overflow: 'hidden',
                          visibility: 'hidden',
                          '@media (max-width: 767.98px)': {
                            visibility: 'visible',
                          },
                          '@media (max-width: 991.98px)': {
                            visibility: 'visible',
                          },
                        }}
                      />
                    </Fragment>
                  ) : (
                    <img
                      loading="lazy"
                      alt={text.Title}
                      className={`image-hidden ${index} `}
                      src={text.src}
                      css={{
                        objectFit: 'cover',
                        width: '100%',
                        backgroundPosition: '50%',
                        position: 'relative',
                        top: 0,
                        minHeight: '50vh',
                        overflow: 'hidden',
                        visibility: 'hidden',
                        '@media (max-width: 992px)': {
                          visibility: 'visible',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  id={`info${index}`}
                  className="info"
                  sx={{
                    position: 'relative',
                    paddingBottom: '160px',
                    paddingRight: {md: '12%', lg: '6vw'},
                    paddingLeft: {md: '4rem', lg: '6vw', xl: '10vw'},
                    flex: '0 0 50%',
                    ' @media (min-width: 992px)': {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingTop: '160px',
                      paddingBottom: '160px',
                      minHeight: ' 100vh',
                    },
                    '@media (max-width: 991.98px)': {
                      display: 'inline-table',
                      px: 0,
                      mx: 'auto',
                      paddingTop: '40px',
                      paddingBottom: '40px',
                      flex: '0 0 83.33333%',
                      maxWidth: '83.33333%',
                    },
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      color: theme.palette.text.alter,
                      mb: '2rem',
                      '@media (max-width: 992px)': {},
                    }}
                  >
                    {languageState.texts.Home.ScrollSticky[index].Title}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: theme.palette.text.alter,
                      mb: '2rem',
                    }}
                  >
                    {languageState.texts.Home.ScrollSticky[index].TextBody}
                  </Typography>
                  <Link
                    style={{
                      color: theme.palette.text.disabled,
                      textDecoration: 'none',
                    }}
                    to={languageState.texts.Home.ScrollSticky[index].LinkButton}
                  >
                    <ButtonStyled
                      text={
                        languageState.texts.Home.ScrollSticky[index].TextButton
                      }
                      color="primary"
                    />
                  </Link>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          className="footer-text row header-col"
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
          sx={{
            '@media (max-width: 767.98px)': {},

            ' @media (max-width: 991.98px)': {
              paddingTop: 0,
              paddingBottom: '80px',
              px: '1rem',
            },

            ' @media (min-width: 992px)': {},
          }}
        >
          {' '}
          <div
            className="container"
            style={{
              textAlign: 'center',
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link
              to="/to-do"
              className={css({color: 'inherit', textDecoration: 'none'})}
            >
              <Typography
                variant="h2"
                sx={{
                  color: theme.palette.text.alter,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {languageState.texts.Home.GoToExperiences}{' '}
                <ArrowForwardIcon
                  className="scroll-sticky-arrow-signal"
                  sx={{
                    fontSize: 'calc(29px + 43 * ((100vw - 320px)/1680))',
                    marginLeft: '1rem',
                  }}
                />
              </Typography>
            </Link>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default ScrollSticky

const {
  REACT_APP_PASSWORD,
  REACT_APP_API_URL,
  REACT_APP_MAPBOX_API,
  REACT_APP_API_WEATHER_KEY,
  // cookies
  REACT_APP_ACCEPT_COOKIE,
  REACT_APP_DECLINE_COOKIE,
  REACT_APP_VISIT_COOKIE,
  REACT_APP_VIEW_COOKIE,
  REACT_APP_REBOUND_COOKIE,
  REACT_APP_COUNTRY_COOKIE,
  REACT_APP_NEWSLETTER_COOKIE,
} = process.env

export default {
  pass: REACT_APP_PASSWORD,
  mapBoxAPI: REACT_APP_MAPBOX_API,
  serverUrl: REACT_APP_API_URL,
  weatherKey: REACT_APP_API_WEATHER_KEY,

  // cookies
  acceptCookie: REACT_APP_ACCEPT_COOKIE,
  declineCookie: REACT_APP_DECLINE_COOKIE,
  visitCookie: REACT_APP_VISIT_COOKIE,
  viewCookie: REACT_APP_VIEW_COOKIE,
  reboundCookie: REACT_APP_REBOUND_COOKIE,
  countryCookie: REACT_APP_COUNTRY_COOKIE,
  newsletterCookie: REACT_APP_NEWSLETTER_COOKIE,
}

import React from 'react'

// @mui/material
import {Typography} from '@mui/material'

// styles
import './hero-content.css'

function HeroContent({text}) {
  return (
    <div className="parallax container" data-depth="0.60">
      <div className="" style={{marginTop: '7rem'}}>
        <div
          className="hero-experience-title "
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Typography
            className="title"
            variant="h1"
            component="h2"
            style={{
              fontWeight: 'bold',
            }}
          >
            {text.Title}
          </Typography>
          <Typography
            className="title"
            variant="h1"
            component="h2"
            style={{
              fontWeight: 'bold',
            }}
          >
            {text.SecondaryTitle}
          </Typography>
        </div>

        <div className="hero-experience-presentation">
          <div className="hero-experience-content">
            <Typography
              variant="h4"
              style={{
                fontWeight: 'bold',
                whiteSpace: 'pre-line',
              }}
            >
              {text.TextSubtitle}
            </Typography>
            <Typography
              style={{
                marginTop: '2rem',
              }}
            >
              {text.TextBody}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroContent

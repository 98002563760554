import React from 'react'

// @mui/material
import {useTheme} from '@mui/material/styles'
import {IconButton, Link} from '@mui/material'

// @mui icons
import {Facebook, Twitter, Instagram} from '@mui/icons-material/'

// own components
import Container from '../../Container/Container'
import {useLanguage} from '../../../context/LanguageProvider'
const SocialIcons = () => {
  const theme = useTheme()
  const {languageState} = useLanguage()

  return (
    <Container sx={{marginTop: '20px'}}>
      <Link
        target="_blank"
        rel="noreferrer"
        href={languageState.texts.SocialMedias.Facebook}
        sx={{marginRight: '20px'}}
      >
        <IconButton
          sx={{
            border: `1px solid ${theme.palette.text.alter}`,
            color: theme.palette.text.alter,
            '&:hover': {
              color: theme.socialmedia.facebook,
              border: `1px solid ${theme.socialmedia.facebook}`,
            },
          }}
          color="side"
          aria-label="facebook"
        >
          <Facebook fontSize="small" />
        </IconButton>
      </Link>
      <Link
        target="_blank"
        rel="noreferrer"
        href={languageState.texts.SocialMedias.Twitter}
        sx={{marginRight: '20px'}}
      >
        <IconButton
          sx={{
            border: `1px solid ${theme.palette.text.alter}`,
            color: theme.palette.text.alter,
            '&:hover': {
              color: theme.socialmedia.twitter,
              border: `1px solid ${theme.socialmedia.twitter}`,
            },
          }}
          color="side"
          aria-label="twitter"
        >
          <Twitter fontSize="small" />
        </IconButton>
      </Link>
      <Link
        target="_blank"
        rel="noreferrer"
        href={languageState.texts.SocialMedias.Instagram}
        sx={{marginRight: '20px'}}
      >
        <IconButton
          sx={{
            border: `1px solid ${theme.palette.text.alter}`,
            color: theme.palette.text.alter,
            '&:hover': {
              color: theme.socialmedia.instagram,
              border: `1px solid ${theme.socialmedia.instagram}`,
            },
          }}
          color="side"
          aria-label="instagram"
        >
          <Instagram fontSize="small" />
        </IconButton>
      </Link>
    </Container>
  )
}

export default SocialIcons

import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

// styles
import './style.css'

function Loading(props) {
  const {backgroundColor, circleColor, className} = props

  const [there, setThere] = useState(false)

  useEffect(() => {
    setThere(true)
  }, [])

  return (
    <div
      style={{backgroundColor: backgroundColor}}
      className={`loading ${!there ? 'loading-hidden' : ''} ${className}`}
    >
      <div className="loader-container">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              stroke={circleColor}
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="4"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

Loading.defaultProps = {
  circleColor: '#fbfbfb',
}

Loading.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
}

export default Loading

/* type-${type}} ${className} */

import config from '../config'

// auth
import {getAuth} from '../auth/auth'

export const newsletter = async options => {
  const response = await fetch(`${config.serverUrl}/customers/newsletter`, {
    method: 'POST',
    body: JSON.stringify({
      ...options,
    }),
    headers: getAuth,
  })
  const data = await response.json()
  return data
}

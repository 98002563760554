import {createTheme, responsiveFontSizes} from '@mui/material/styles'

import darkScrollbar from './scroll'

let dark = createTheme({
  palette: {
    type: 'dark',
    contrastThreshold: 4.5,
    primary: {
      main: '#f6ad3c',
      light: '#f7bd63',
      dark: '#ac792a',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#338dc9',
      light: '#0071bc',
      dark: '#004f83',
      contrastText: '#ffffff',
    },
    disabled: {
      dark: '#9a9a9a',
      main: '#c3c3c3',
      light: '#e3e3e3',
    },
    error: {
      main: '#f44336',
      light: '#f6685e',
      dark: '#aa2e25',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff9800',
      light: '#ffac33',
      dark: '#b26a00',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    info: {
      main: '#2196f3',
      light: '#4dabf5',
      dark: '#1769aa',
      contrastText: '#ffffff',
    },
    success: {
      main: '#4caf50',
      light: '#6fbf73',
      dark: '#357a38',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    iconContainedButton: {
      main: '#fbb03b',
      light: '#fbbf62',
      dark: '#fbbf62',
      contrastText: '#ffffff',
    },
    buttonligth: {
      contrastText: '#af7b29',
      light: '#fbbf62',
      dark: '#af7b29',
      main: '#ffffff',
    },
    buttondark: {
      main: '#ffac33',
      light: '#fbbf62',
      dark: '#af7b29',
      contrastText: '#ffffff',
    },
    divider: '#ffffff',
    background: {
      default: '#f0f0f0',
      paper: '#e3e3e3',
      button: '#e3e3e3',
      paper2: '#e3e3e3',
    },

    text: {
      alter: '#222',
      main: '#fff',
      primary: '#ffffff',
      secondary: 'rgba(255,255,255,0.7)',
      disabled: 'rgba(255,255,255,0.5)',
      hint: 'rgba(255,255,255,0.5)',
    },
    side: {
      main: 'rgba(255, 255, 255, 0.7)',
    },
  },
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
    h1: {
      fontSize: '5rem',
      fontWeight: 'bold',
      lineHeight: 1,
      letterSpacing: '-0.01em',
      fontFamily: 'Times New Roman, Times, serif',
    },
    h2: {
      fontSize: '3.75rem',
      fontWeight: 'bold',
      lineHeight: 1,
      letterSpacing: '0.01em',
      fontFamily: 'Times New Roman, Times, serif',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 'bold',
      lineHeight: 1.16,
      letterSpacing: '0em',
      fontFamily: 'Times New Roman, Times, serif',
    },
    h4: {
      fontSize: '2.125rem',
      fontWeight: 'bold',
      lineHeight: 1.23,
      letterSpacing: '0.02em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: 1.33,
      letterSpacing: '0em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.02em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.01em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.01em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: '0.02em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.03em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 2.66,
      letterSpacing: '0.08em',
      fontFamily: 'Arial, Helvetica, sans-serif',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar({
          track: '#FFFFFF00',
          thumb: '#405163',
          active: '#405163',
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          marginTop: '3px',
        },
      },
    },
  },

  socialmedia: {
    facebook: '#3b5998',
    youtube: '#bb0000',
    twitter: '#55acee',
    linkedin: '#007bb5',
    instagram: '#125688',
    pinterest: '#cb2027',
    tumblr: '#32506d',
    imgur: '#85bf25',
    telegram: '#0088cc',
    flickr: '#ff0084',
    vimeo: '#aad450',
    foursquare: '#0072b1',
    hi5: '#fd9827',
    whatsapp: '#4dc247',
    snapchat: '#fffc00',
    soundCloud: '#ff8800',
    seriscope: '#35A3C6',
    tinder: '#E34514',
  },
})
dark = responsiveFontSizes(dark)
export default dark

import React, {useState, useEffect, useCallback, Suspense} from 'react'
import loadable from '@loadable/component'

import {getCookie, createCookie} from 'some-javascript-utils/browser'

// storage-function
import {storageFunction} from 'storage-function'

// react-router-dom
import {BrowserRouter, Routes, Route} from 'react-router-dom'

// theme
import {ThemeProvider} from '@mui/material/styles'
import light from './assets/theme/light'
import dark from './assets/theme/dark'

// @mui components
import {Box, CssBaseline} from '@mui/material'

// services
import {sendVisitCookie, sendReboundCookie} from './services/analytics'

// layout
import View from './layouts/View/View'

// views
import Home from './views/Home/Home'
import NotFound from './views/NotFound/NotFound'

// styles
import './app.css'

// contexts
import {useMode} from './context/ModeProvider'
import {LoadingProvider} from './context/LoadingProvider'
import {ShowHoverProvider} from './context/ShowHoverContext'

import Handler from './views/NotFound/Handler'

// config
import config from './config'

const WhatIs = loadable(() => import('./components/Mark/WhatIs/WhatIs'))
const HowItWasWade = loadable(() =>
  import('./components/Mark/HowItWasWade/HowItWasWade'),
)
const FoundingPrinciples = loadable(() =>
  import('./components/Mark/FoundingPrinciples/FoundingPrinciples'),
)
const TrinidadCuba = loadable(() =>
  import('./components/Mark/TrinidadCuba/TrinidadCuba'),
)
const ContactUs = loadable(() => import('./views/ContactUs/ContactUs'))
const AboutUs = loadable(() => import('./views/AboutUs/AboutUs'))

const ToTop = loadable(() => import('./components/ToTop/ToTop'))
const Explore = loadable(() => import('./views/Explore/Explore'))
const SiteDetails = loadable(() => import('./views/SiteDetails/SiteDetails'))
const View4 = loadable(() => import('./views/View4/View4'))
const Detail = loadable(() => import('./views/Detail/Detail'))
const DetailProduct = loadable(() =>
  import('./views/DetailProduct/DetailProduct'),
)
const SessionPresentation = loadable(() =>
  import('./views/SessionPresentation/SessionPresentation'),
)
const ListItemView = loadable(() => import('./views/ListItemView/ListItemView'))
const SessionCommon = loadable(() =>
  import('./views/SessionCommon/SessionCommon'),
)
const CookieBox = loadable(() => import('./components/CookieBox/CookieBox'))
const RemoveData = loadable(() => import('./views/Auth/RemoveData'))

const App = () => {
  const {modeState, setModeState} = useMode()
  const [, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    setLoading(true)
    document.body.style.overflowX = 'hidden'
    // local theme
    const localTheme = storageFunction.fromLocalStorage('mode')
    if (localTheme !== null) {
      const transpiledTheme = localTheme.substring(1, localTheme.length - 1)
      setModeState({type: 'set', to: transpiledTheme})
    }

    setLoading(false)
  }, [setModeState])

  const fetchCountry = () => {
    fetch('https://api.ipregistry.co/?key=tryout')
      .then(function (response) {
        return response.json()
      })
      .then(function (payload) {
        createCookie(
          config.countryCookie,
          1,
          payload.location.country.name + ', ' + payload.location.city,
        )
      })
  }

  const handleVisit = useCallback(async () => {
    if (
      getCookie(config.reboundCookie) === 'rebound' &&
      !getCookie(config.visitCookie).length
    ) {
      sendVisitCookie()
    }
  }, [])

  // fetching data from local storage
  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (!getCookie(config.countryCookie).length) fetchCountry()
    if (
      !getCookie(config.reboundCookie).length &&
      !getCookie(config.visitCookie).length
    ) {
      sendReboundCookie()
      document.body.addEventListener('click', handleVisit)
      document.body.addEventListener('scroll', handleVisit)
    }
    return () => {
      if (!getCookie(config.reboundCookie)) {
        document.body.removeEventListener('click', handleVisit)
        document.body.removeEventListener('scroll', handleVisit)
      }
    }
  }, [])

  return (
    <div className="App">
      <ThemeProvider theme={modeState.mode === 'light' ? light : dark}>
        <CssBaseline />
        <Box className="App">
          <CookieBox />
          <AppRoutes />
        </Box>
      </ThemeProvider>
    </div>
  )
}

function AppRoutes() {
  return (
    <Suspense>
      <ToTop />
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/auth/"
            element={
              <Handler>
                <LoadingProvider>
                  <ShowHoverProvider>
                    <View noFooter />
                  </ShowHoverProvider>
                </LoadingProvider>
              </Handler>
            }
          >
            <Route exact path="/auth/remove-my-data" element={<RemoveData />} />
          </Route>
          <Route
            path="/"
            element={
              <Handler>
                <LoadingProvider>
                  <ShowHoverProvider>
                    <View />
                  </ShowHoverProvider>
                </LoadingProvider>
              </Handler>
            }
          >
            <Route index element={<Home />} />

            {/* Vista de detalles de lugar o evento) */}
            <Route exact path="/detail" element={<Detail />} />
            {/* Contact Us */}
            <Route exact path="/contact-us/" element={<ContactUs />} />
            {/* Vista de detalles de experiencias */}
            <Route path="/details/:index" element={<SiteDetails />} />

            {/* Vista de detalles de tipos de lugares(Ejemplo: Museos) */}
            <Route exact path="/places/:type" element={<View4 />} />
            {/* View Information */}
            <Route
              path="/discover/city-brand/trinidad-cuba"
              element={<TrinidadCuba />}
            />
            <Route path="/discover/city-brand/what-is" element={<WhatIs />} />
            <Route
              path="/discover/city-brand/how-it"
              element={<HowItWasWade />}
            />
            <Route
              path="/discover/city-brand/statutes-rights-duties"
              element={<FoundingPrinciples />}
            />
            <Route path="/news" exact element={<ListItemView />} />
            <Route path="/:title/:session" element={<SessionCommon />} />
            <Route path="/list-item/about-us" element={<AboutUs />} />
            <Route path="/:title" element={<SessionPresentation />} />
            <Route path="/:title/:session/:type" element={<ListItemView />} />

            {/* Vista de detalles de rutas) */}
            <Route
              exact
              path="/details/route/:name"
              element={<DetailProduct />}
            />
            <Route path="/details/:collection/:name" element={<Detail />} />
          </Route>
          <Route
            path="/explore"
            element={
              <Handler>
                <Explore />
              </Handler>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default App

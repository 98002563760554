/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import {getAuth} from '../auth/auth'
import config from '../config'

import {getCookie} from 'some-javascript-utils/browser'

const loadFromServerPost = async (collection = '', what = []) => {
  try {
    const response = await axios.post(
      `${config.serverUrl}/api-client/post`,
      {collection, what},
      {
        headers: getAuth,
      },
    )
    const data = await response.data
    if (data.error === undefined) return data
    return {error: response.statusText}
  } catch (err) {
    return {error: String(err)}
  }
}

/**
 *
 * @param {string} text
 * @param {string[]} models
 * @param {string} attribute
 * @param {number} page
 * @param {number} count
 * @returns
 */
export const searchNew = async (
  text,
  models,
  attribute = '',
  attributes = [],
  page = 0,
  count = 10,
  query = [],
) => {
  const response = await axios.post(
    `${config.serverUrl}/search/new`,
    {text, models, attribute, attributes, page, count, query},
    {
      headers: {
        ...getAuth,
        Authorization: `Bearer ${getCookie(config.basicKeyCookie)}`,
      },
    },
  )
  return await response.data
}

export default loadFromServerPost

import React from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import useScreenSize from '../hooks/useScreenSize'

const ShowHoverContext = React.createContext()

function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false)
  React.useLayoutEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch],
  )
}
const defaultInitialSlide = [
  {
    id: 0,
    image:
      'https://ik.imagekit.io/tx6beroitnm/ShowHover/1_pRSGQ2cSj7.jpeg',
    overflow: 'hidden',
    selected: false,
    opacity: 1,
  },
  {
    id: 1,
    image:
      'https://ik.imagekit.io/tx6beroitnm/ShowHover/wallpaperflare.com_wallpaper__4__ewUZjvVXL.jpg',
    overflow: 'hidden',
    selected: false,
    opacity: 1,
  },
  {
    id: 2,
    image:
      'https://ik.imagekit.io/tx6beroitnm/ShowHover/3_Aozp_hCZW.jpeg',
    overflow: 'hidden',
    selected: false,
    opacity: 1,
  },
  {
    id: 3,
    image:
      'https://ik.imagekit.io/tx6beroitnm/Para_cosas_de_playa/el_alma_de_cuba_317733142_1553416061786874_4865383684871353885_n__NSYLsJFo.jpg',
    overflow: 'hidden',
    selected: false,
    opacity: 1,
  },
]

function useSlide() {
  const {width} = useScreenSize()

  const [newState, setNewState] = React.useReducer(
    slideReducer,
    defaultInitialSlide,
  )

  function slideReducer(oldSlide, action) {
    const {type} = action
    switch (type) {
      case 'set': {
        const {newSlide} = action
        return newSlide
      }

      case 'reset': {
        return oldSlide
      }

      case 'setVisible': {
        const newSlide = [...oldSlide]
        if (width > 1199) newSlide.forEach(item => (item.overflow = 'hidden'))
        if (width < 1200) {
          if (newSlide.length > 3) {
            newSlide.forEach(item => {
              item.selected
                ? (item.overflow = 'visible')
                : (item.overflow = 'hidden')
            })
          } else {
            newSlide.forEach(item => {
              item.selected
                ? (item.overflow = 'visible')
                : (item.overflow = 'hidden')
            })
          }
        }
        return newSlide
      }

      case 'setSelected': {
        const {id} = action
        const newSlide = [...oldSlide]

        newSlide.forEach(item => {
          if (item.id === Number(id)) {
            item.selected = true
          } else {
            item.selected = false
          }
        })
        return newSlide
      }

      case 'setOpacity': {
        const newSlide = [...oldSlide]
        if (width > 1199) {
          newSlide.forEach(item => {
            item.opacity = 1
          })
        }
        if (width < 1200) {
          if (newSlide.length > 3) {
            newSlide.forEach(item => {
              item.selected ? (item.opacity = 1) : (item.opacity = 0)
            })
          } else {
            if (!newSlide.some(item => item.selected)) {
              newSlide.forEach(item => {
                item.opacity = 1
              })
            } else {
              newSlide.forEach(item => {
                item.selected ? (item.opacity = 1) : (item.opacity = 0)
              })
            }
          }
        }
        return newSlide
      }
      default:
        return [...oldSlide]
    }
  }

  const safeSetNewSlide = useSafeDispatch(setNewState)

  const setSlide = React.useCallback(
    data => {
      safeSetNewSlide({type: 'set', ...data})
    },
    [safeSetNewSlide],
  )

  const setSlideOverflow = React.useCallback(() => {
    safeSetNewSlide({type: 'setVisible'})
  }, [safeSetNewSlide])

  const setSlideOpacity = React.useCallback(() => {
    safeSetNewSlide({type: 'setOpacity'})
  }, [safeSetNewSlide])

  const reset = React.useCallback(() => {
    safeSetNewSlide({type: 'set', newSlide: defaultInitialSlide})
  }, [safeSetNewSlide])

  const resetSplit = React.useCallback(() => {
    return defaultInitialSlide
  }, [safeSetNewSlide])

  const setSlideSelected = React.useCallback(
    data => {
      safeSetNewSlide({type: 'setSelected', ...data})
    },
    [safeSetNewSlide],
  )
  return {
    setSlide,
    reset,
    safeSetNewSlide,
    newState,
    setSlideOpacity,
    setSlideOverflow,
    setSlideSelected,
    resetSplit,
  }
}
const ShowHoverProvider = ({children}) => {
  const {
    setSlide,
    reset,
    safeSetNewSlide,
    newState,
    setSlideOpacity,
    setSlideOverflow,
    setSlideSelected,
    resetSplit,
  } = useSlide()

  let value = {
    setSlide,
    reset,
    safeSetNewSlide,
    newState,
    setSlideOpacity,
    setSlideOverflow,
    setSlideSelected,
    resetSplit,
  }

  return (
    <ShowHoverContext.Provider value={value}>
      {children}
    </ShowHoverContext.Provider>
  )
}

ShowHoverProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// hooks
const useShowHoverContext = () => {
  const context = React.useContext(ShowHoverContext)
  if (context === undefined)
    throw new Error('ShowHoverContext must be used within a Provider')
  return context
}

export {ShowHoverProvider, useShowHoverContext}

import React, {useEffect, useState} from 'react'

// @emotion/css
import {css} from '@emotion/css'

// prop-types
import PropTypes from 'prop-types'
// mui components
import {useTheme} from '@mui/material/styles'
// images
import logonotext from '../../assets/images/logos/logonotext.png'

// context

// styles
import './style.css'

export default function BigLoading() {
  const theme = useTheme()
  const [active, setActive] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 100)
  }, [])

  return (
    <div
      id="contenedor_carga"
      className={css({
        paddingTop: '100px',
        pointerEvents: 'none',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: 200,
        top: 0,
        left: 0,
      })}
      style={{
        background: theme.palette.primary.main,
      }}
    >
      <div className="logo-entrance">
        <div className="logo-container">
          <img className="logo-image" src={logonotext} alt="logo trinidad" />
        </div>
      </div>

      {active ? (
        <div className="bar-entrance progress_bar">
          <div className="bar_h" />
        </div>
      ) : null}
    </div>
  )
}
BigLoading.defaultProps = {
  visible: true,
}

BigLoading.propTypes = {
  visible: PropTypes.bool.isRequired,
}

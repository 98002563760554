import React, {useMemo, useState} from 'react'

import {css} from '@emotion/css'

// react-router-dom
import {Link} from 'react-router-dom'
import {useForm, Controller} from 'react-hook-form'

// @mui components
import {useTheme} from '@mui/material/styles'
import {
  Typography,
  Link as MuiLink,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  Alert,
} from '@mui/material'

import {
  Email,
  Launch,
  WhatsApp,
  Facebook,
  Twitter,
  Instagram,
} from '@mui/icons-material'

// context
import {useLanguage} from '../../context/LanguageProvider'
import {useRoute} from '../../context/RouterProvider'

// own components
import Container from '../Container/Container'
import SocialIcons from './SocialIcons/SocialIcons'
import CopyrightRow from './CopyrightRow/CopyrightRow'

// services
import {sendNewsletterCookie} from '../../services/analytics'
import {newsletter} from '../../services/newsletter'

// images
import logo from '../../assets/images/logos/logo-b-new.svg'

import googleplay from './../../assets/images/market apps/agoogle play sobre fondo negro.svg'
import appstore from './../../assets/images/market apps/aApp store sobre fondo negro.svg'

const Footer = () => {
  // const location = useLocation()
  const {routeState, setRouteState} = useRoute()
  const {languageState} = useLanguage()
  const theme = useTheme()

  const height = useMemo(() => {
    return css({
      height: '59.5px',
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: '10px',
    })
  }, [])

  const {control, handleSubmit} = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
  })

  const handleLink = e => {
    const {id} = e.target
    setRouteState({type: 'set', to: Number(id.substring(2))})
  }

  const icons = {
    phone: <WhatsApp />,
    email: <Email />,
  }

  const [error, setError] = useState('')

  const contact = async d => {
    setError('')
    try {
      sendNewsletterCookie()
      const response = await newsletter(d)
      if (response.exist) setError('exist')
      else setError('OK')
    } catch (err) {
      console.error(err)
      setError(languageState.texts.Error.SomeWrong)
    }
  }

  return (
    <Box
      className="row"
      sx={{backgroundColor: `${theme.palette.primary.main}`}}
    >
      <div
        className="container"
        style={{
          position: 'relative',
        }}
      >
        <Box
          className="container"
          sx={{
            background: `${theme.palette.background.paper}`,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            py: {md: '4rem', xs: '1rem ', xl: '4rem '},
          }}
        >
          <Box sx={{maxWidth: '75%', mx: 'auto'}}>
            <Typography
              variant="h3"
              color="primary"
              sx={{color: theme.palette.text.alter}}
            >
              {languageState.texts.Newsletter.Title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{color: theme.palette.text.alter, marginTop: '30px'}}
            >
              {languageState.texts.Newsletter.Subtitle}
            </Typography>
            {error !== 'OK' ? (
              <Container
                component="form"
                extraProps={{onSubmit: handleSubmit(contact)}}
                sx={{
                  paddingRight: {lg: '40px', md: 0},
                  gap: '15px',
                  marginTop: {xs: '10px', md: '30px'},
                  flexDirection: {md: 'row', xs: 'column'},
                }}
              >
                <Controller
                  name="email"
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      required
                      inputProps={{autoComplete: 'xyz12asdsad3'}}
                      color="primary"
                      id="xyz123"
                      name="xyz123"
                      autoComplete="nope"
                      size="small"
                      type="email"
                      label={languageState.texts.Newsletter.Email.Label}
                      placeholder={
                        languageState.texts.Newsletter.Email.Placeholder
                      }
                      variant="outlined"
                      sx={{
                        fieldset: {
                          border: `1px solid ${theme.palette.primary.light}`,
                          borderRadius: '40px',
                        },
                        label: {
                          color: theme.palette.text.alter,
                          paddingRight: '10px',
                        },
                        input: {
                          color: theme.palette.text.alter,
                          paddingLeft: '20px',
                        },
                        'input::placeholder': {
                          color: theme.palette.text.alter,
                        },
                        width: {md: '40vw', xs: '100%'},
                      }}
                    />
                  )}
                />
                <Button
                  type="submit"
                  color="primary"
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: '40px',
                    gap: '10px',
                    p: {md: '0 15px', xs: '0 30px'},
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography variant="subtitle1">
                    {languageState.texts.Newsletter.Submit}{' '}
                  </Typography>
                </Button>
              </Container>
            ) : null}
            {error === 'OK' ? (
              <Alert severity="success">
                {languageState.texts.Newsletter.Sent}
              </Alert>
            ) : null}
            {error.length && error === 'exist' ? (
              <Alert severity="warning">
                {languageState.texts.Newsletter.Exist}
              </Alert>
            ) : null}
            {error.length && error !== 'OK' && error !== 'exist' ? (
              <Alert severity="error">{error}</Alert>
            ) : null}
            <Container sx={{justifyContent: 'center'}}>
              <SocialIcons />
            </Container>
          </Box>
        </Box>
      </div>
      <Box
        className="row"
        sx={{
          marginTop: '-160px',
          paddingTop: '220px',
          background: theme.palette.background.default,
          flexGrow: 1,
        }}
      >
        <div className="container">
          <Grid
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: {xs: '40px', lg: '20px'},
            }}
            container
          >
            <Grid
              xs={12}
              lg={5}
              item
              sx={{
                padding: '0',
                gap: '10px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <img
                loading="lazy"
                width={190}
                height={50}
                src={logo}
                alt=""
                style={{marginBottom: '10px', filter: 'invert(1)'}}
              />
              <Typography
                variant="subtitle1"
                sx={{color: theme.palette.text.alter}}
              >
                {languageState.texts.Footer.Description}
              </Typography>
              <Container>
                <Typography
                  variant="subtitle1"
                  sx={{color: theme.palette.text.alter, mt: 2}}
                >
                  {languageState.texts.Footer.Download}
                </Typography>
              </Container>
              <Container
                sx={{
                  gap: '10px',
                  flexDirection: {xs: 'column', md: 'row'},
                }}
              >
                <MuiLink
                  id="home"
                  target="_blank"
                  underline="none"
                  href="https://play.google.com/store/apps/details?id=com.inmersoft.trinidad"
                  className={css({
                    width: '180px',
                    height: '54px',
                  })}
                >
                  <img
                    loading="lazy"
                    width={'100%'}
                    height={'100%'}
                    src={googleplay}
                    alt=""
                    style={{}}
                  />
                </MuiLink>
                <MuiLink
                  id="home"
                  target="_blank"
                  underline="none"
                  href="https://play.google.com/store/apps/details?id=com.inmersoft.trinidad"
                  className={css({
                    width: '180px',
                    height: '54px',
                    '@media (max-width: 768px)': {
                      display: 'flex',
                      justifyContent: 'flex-start',
                    },
                  })}
                >
                  <img
                    loading="lazy"
                    width={'100%'}
                    height={'100%'}
                    src={appstore}
                    alt=""
                    style={{}}
                  />
                </MuiLink>
              </Container>
            </Grid>
            <Grid xs={5} sm={2} md={2} item>
              <Container direction="column">
                <div className={height}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textTransform: 'none',
                      fontWeight: '700',
                      color: theme.palette.text.alter,
                    }}
                  >
                    {languageState.texts.Navbar.Title}
                  </Typography>
                </div>
                <Container
                  align="flex-start"
                  sx={{gap: '10px'}}
                  direction="column"
                >
                  {languageState.texts.Navbar.Links.filter(
                    item => item.label,
                  ).map((item, i) => (
                    <Container align="flex-start" key={item.id}>
                      <Link
                        style={{
                          color: theme.palette.text.alter,
                          textDecoration: 'none',
                        }}
                        id={`fl${i}`}
                        to={item.route}
                      >
                        <Typography
                          id={`fb${i}`}
                          onClick={handleLink}
                          variant="subtitle1"
                          sx={{
                            textAlign: 'left',
                            textTransform: 'none',
                            color:
                              item.index === routeState.route
                                ? theme.palette.primary.main
                                : theme.palette.text.alter,
                            '&:hover': {
                              color: theme.palette.primary.main,
                            },
                          }}
                          size="medium"
                        >
                          {item.label}
                        </Typography>
                      </Link>
                    </Container>
                  ))}
                </Container>
              </Container>
            </Grid>
            <Grid xs={5} sm={2} md={2} item>
              <Container direction="column">
                <div className={height}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textTransform: 'none',
                      fontWeight: '700',
                      color: theme.palette.text.alter,
                    }}
                  >
                    {languageState.texts.Footer.UtilLinks.Title}
                  </Typography>
                </div>
                <Container direction="column" sx={{gap: '10px'}}>
                  {languageState.texts.Footer.UtilLinks.Links.map((item, i) => (
                    <Container key={item.id} align="center">
                      {item.external ? (
                        <a
                          style={{
                            color: theme.palette.text.alter,
                            textDecoration: 'none',
                          }}
                          target="_blank"
                          rel="noreferrer"
                          id={`fl${i}`}
                          href={item.external}
                        >
                          <Typography
                            id={`fb${i}`}
                            onClick={handleLink}
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              alignItems: 'center',
                              textTransform: 'none',
                              color:
                                item.index === routeState.route
                                  ? 'primary'
                                  : theme.palette.text.alter,
                              '&:hover': {
                                color: theme.palette.primary.main,
                              },
                            }}
                            variant="subtitle1"
                          >
                            {item.label}
                            <Launch sx={{fontSize: '20px'}} />
                          </Typography>
                        </a>
                      ) : (
                        <Link
                          style={{
                            color: theme.palette.text.alter,
                            textDecoration: 'none',
                          }}
                          id={`fl${i}`}
                          to={item.route}
                        >
                          <Typography
                            id={`fb${i}`}
                            onClick={handleLink}
                            sx={{
                              textTransform: 'none',
                              color:
                                item.index === routeState.route
                                  ? 'primary'
                                  : theme.palette.text.alter,
                              '&:hover': {
                                color: theme.palette.primary.main,
                              },
                            }}
                            variant="subtitle1"
                          >
                            {item.label}
                          </Typography>
                        </Link>
                      )}
                    </Container>
                  ))}
                </Container>
              </Container>
            </Grid>
            <Grid xs={10} sm={4} md={3} item>
              <Container direction="column">
                <div className={height}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textTransform: 'none',
                      fontWeight: '700',
                      color: theme.palette.text.alter,
                    }}
                  >
                    {languageState.texts.Footer.ContactLinks.Title}
                  </Typography>
                </div>
                <Container sx={{gap: '10px'}}>
                  {languageState.texts.Footer.ContactLinks.Links.map(
                    (item, i) => (
                      <Container key={item.id} align="center">
                        {item.action ? (
                          <MuiLink
                            style={{
                              cursor: 'pointer',
                              color: theme.palette.text.alter,
                              textDecoration: 'none',
                            }}
                            id={`fl${i}`}
                            href={item.action}
                          >
                            <IconButton
                              sx={{
                                ':hover': {
                                  color: theme.palette.primary.main,
                                },
                              }}
                            >
                              {icons[item.id]}
                            </IconButton>
                          </MuiLink>
                        ) : (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              textTransform: 'none',
                              color: theme.palette.text.alter,
                            }}
                          >
                            {item.label}
                          </Typography>
                        )}
                      </Container>
                    ),
                  )}
                  <Container align="center">
                    <MuiLink
                      target="_blank"
                      rel="noreferrer"
                      href={languageState.texts.SocialMedias.Facebook}
                      style={{
                        color: theme.palette.text.alter,
                        textDecoration: 'none',
                      }}
                    >
                      <IconButton
                        sx={{
                          '&:hover': {
                            color: theme.palette.primary.main,
                          },
                        }}
                        aria-label="facebook"
                      >
                        <Facebook />
                      </IconButton>
                    </MuiLink>
                  </Container>
                  <Container align="center">
                    <MuiLink
                      target="_blank"
                      rel="noreferrer"
                      href={languageState.texts.SocialMedias.Twitter}
                      style={{
                        color: theme.palette.text.alter,
                        textDecoration: 'none',
                      }}
                    >
                      <IconButton
                        sx={{
                          '&:hover': {
                            color: theme.palette.primary.main,
                          },
                        }}
                        aria-label="twitter"
                      >
                        <Twitter />
                      </IconButton>
                    </MuiLink>
                  </Container>
                  <Container align="center">
                    <MuiLink
                      target="_blank"
                      rel="noreferrer"
                      href={languageState.texts.SocialMedias.Instagram}
                      style={{
                        color: theme.palette.text.alter,
                        textDecoration: 'none',
                      }}
                    >
                      <IconButton
                        sx={{
                          '&:hover': {
                            color: theme.palette.primary.main,
                          },
                        }}
                        aria-label="instagram"
                      >
                        <Instagram />
                      </IconButton>
                    </MuiLink>
                  </Container>
                </Container>
              </Container>
            </Grid>
          </Grid>
        </div>

        <CopyrightRow />
      </Box>
    </Box>
  )
}

export default Footer

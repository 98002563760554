import React, {useCallback} from 'react'
import {Link} from 'react-router-dom'
import {gsap} from 'gsap'

// @mui/material
import {Box, Typography, Button} from '@mui/material'
import {Visibility as VisibilityIcon} from '@mui/icons-material/'

import useArrayRef from '../../hooks/useArrayRef'
import useScreenSize from '../../hooks/useScreenSize'
import {useShowHoverContext} from '../../context/ShowHoverContext'

import {css} from '@emotion/css'

import ButtonStyled from '../ButtonStyled/ButtonStyled'
import {IKImage} from 'imagekitio-react'

function ShowHover({texts}) {
  const {width} = useScreenSize()
  const [setRefItem] = useArrayRef()
  const [slideItem, setSlideItem] = React.useState()
  const [imgContainer, setImgContainer] = React.useState()
  const {newState, safeSetNewSlide, setSlideOpacity, setSlideOverflow} =
    useShowHoverContext()

  React.useEffect(() => {
    setSlideItem(gsap.utils.toArray('.swiper-slide__item'))
    setImgContainer(gsap.utils.toArray('.image-item'))
    if (width > 1199) {
      setSlideOverflow()
      setSlideOpacity()
    } else if (width < 1200) {
      safeSetNewSlide({
        type: 'setSelected',
        id: 0,
      })
      setSlideOverflow()
      setSlideOpacity()
    }
  }, [texts, safeSetNewSlide, setSlideOpacity, setSlideOverflow, width])

  const onClickButton = useCallback(
    ({currentTarget}) => {
      const theIndex = texts.findIndex(text => text.id === currentTarget.value)
      safeSetNewSlide({
        type: 'setSelected',
        id: theIndex,
      })
      safeSetNewSlide({type: 'setOpacity'})
      safeSetNewSlide({type: 'setVisible'})
    },
    [texts, safeSetNewSlide],
  )

  const handleMouseEnter = useCallback(
    e => {
      const {id} = e.target
      if (width > 1199) {
        slideItem.forEach((item, index) => {
          if (item.id === id) {
            let move = gsap.to(imgContainer[index], {
              opacity: 1,
              onComplete: () => {
                safeSetNewSlide({type: 'setSelected', id})
              },
            })
            move.play()
          } else {
            let move = gsap.to(imgContainer[index], {
              opacity: 0,
            })
            move.play()
          }
        })
      }
    },
    [imgContainer, safeSetNewSlide, slideItem, width],
  )

  const handleMouseLeave = useCallback(() => {
    if (width > 1199) {
      imgContainer.forEach(imgC => {
        gsap.to(imgC, {
          opacity: 1,
        })
      })
    }
  }, [imgContainer, width])

  const printSliders = useCallback(
    () =>
      texts?.map((item, index) => (
        <Box
          key={`${item.id}`}
          className="swiper-slide__item"
          ref={setRefItem}
          id={index}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            position: 'relative',
            width: `calc(100%/${texts.length})`,
            overflow: 'hidden',
            transform: 'scale(1)',
            height: '100%',
            opacity: newState[index].opacity,
            zIndex: 20,
            '@media(min-width: 1200px)': {
              '&:hover': {
                overflow: 'visible',
                opacity: 1,
                '& .text-description': {
                  height: 'auto',
                  transform: 'scaleY(1)',
                  opacity: 1,
                  marginBottom: '1rem',
                  transitionProperty: 'all',
                  transitionDuration: ' 0.300s',
                  transitionTimingFunction: 'ease-out',
                  transitionDelay: '0s',
                },
                '& .item-button': {
                  transform: 'scaleY(1)',
                  opacity: 1,
                  height: 'auto',
                },
                '& .title-item': {
                  alignItems: 'flex-end',
                },
              },
            },

            '&:hover': {
              zIndex: 1,
              '& .icon-container ': {
                opacity: 0,
                flex: '1',
              },
              '& .content': {
                justifyContent: 'none',
                height: '100%',
              },
            },
            '@media (max-width: 1199px)': {
              width: '100vw',
              overflow: newState[index].overflow,
            },
          }}
        >
          <Box
            sx={{
              height: '100%',
              boxSizing: 'border-box',
              '@media (max-width: 1199px)': {
                width: '100vw',
              },
            }}
          >
            <Box
              className={`image-item`}
              id={index}
              sx={{
                pointerEvents: 'none',
                width: '100vw',
                opacity: newState[index].opacity,
                zIndex: 1,
                height: '100%',
                position: 'absolute',
                display: 'flex',
                left: `calc((100vw/${texts.length} *${index})*-1)`,
                background:
                  'linear-gradient(180deg,rgba(2,8,33,0.25) 0%,#020821 100%)',
                '@media (max-width: 1199px)': {
                  left: '0px !important',
                },
              }}
            >
              <IKImage
                urlEndpoint={'https://ik.imagekit.io/tx6beroitnm'}
                src={`${item.src}?tr=q-100,w-${window.innerWidth}`}
                lqip={{active: true, quality: 20, blur: 20}}
                loading="lazy"
                alt={item?.Title}
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  transitionProperty: 'opacity',
                  transitionDuration: ' 0.30s',
                  transitionTimingFunction: 'ease',
                  transitionDelay: '0s',
                }}
              />
            </Box>
            <Box
              id={index}
              className="content"
              sx={{
                padding: '2rem 2rem',
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
                '@media (max-width: 1199px)': {
                  height: '100%',
                  padding: '3rem 1rem',
                  mx: 'auto',
                  flex: {
                    xl: '0 0 66.66667%',
                    md: '0 0 70.66667%',
                    xs: '0 0 85.66667%',
                  },
                  maxWidth: {
                    xl: '66.66667%',
                    md: '70.66667%',
                    xs: '85.66667%',
                  },
                },
              }}
            >
              <Box
                id={index}
                className="icon-container"
                sx={{
                  opacity: 1,
                  display: 'flex',
                  flex: 2,
                  flexGrow: 1.5,
                  alignItems: 'flex-end',
                  transition: 'all linear 250ms',
                  mb: 5,
                  '@media (max-width: 1199px)': {
                    display: 'none',
                    opacity: 0,
                    transition: 'none',
                    flex: '0',
                  },
                }}
              />

              <Typography
                id={index}
                className="title-item"
                variant="h4"
                sx={{
                  marginBottom: '1rem',
                  height: '150px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  textAlign: 'center',
                  px: texts.length < 3 ? 5 : 0,
                  '@media (max-width: 1199px)': {
                    px: 1,
                  },
                }}
              >
                {item?.Title}
              </Typography>

              <Link
                className={css({color: 'inherit', textDecoration: 'none'})}
                preventScrollReset={true}
                to={`/details/${item?.id}`}
              >
                <ButtonStyled
                  className="item-button"
                  text={item?.TextButton}
                  sx={{
                    height: 0,
                    transform: 'scaleY(0)',
                    borderRadius: '999rem',
                    textTransform: 'none',
                    boxShadow: 'none',
                    transitionProperty: 'all',
                    transitionDuration: ' 0.15s',
                    transitionTimingFunction: 'ease-out',
                    transitionDelay: '0s',

                    '@media (max-width: 1198px)': {
                      transform: newState[index].selected && 'scaleY(1)',
                      height: newState[index].selected && 'auto',
                    },
                    '& .icon-arrow-button': {
                      transform: 'translateX(0)',
                      transitionProperty: 'all',
                      transitionDelay: '0s',
                    },
                    '&:hover': {
                      boxShadow: 'none',
                      color: '#ffffff',
                      '& .icon-arrow-button': {
                        transform: 'translateX(0.25em)',
                      },
                    },
                  }}
                />
              </Link>
            </Box>
          </Box>
        </Box>
      )),
    [texts, newState, handleMouseEnter, handleMouseLeave, setRefItem],
  )

  return (
    <Box
      id="container"
      className="swiper-container"
      sx={{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        overflow: 'hidden',
        listStyle: 'none',
        zIndex: 1,
        '@media (min-width: 1200px)': {
          height: '650px',
        },
        '@media (max-width: 1199px)': {
          height: '500px',
        },

        '@media (max-width: 600px)': {
          height: '400px',
          minHeight: '300px',
        },
      }}
    >
      <Box
        className="swiper-wrapper"
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          zIndex: 1,
          display: 'flex',
          transitionProperty: 'transform',
          boxSizing: 'content-box',
          transform: 'translate3d(0px,0,0)',
        }}
      >
        {printSliders()}
      </Box>
      <Box
        className="swiper-pagination-bullets"
        sx={{
          bottom: '10px',
          left: 0,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          textAlign: 'center',
          overflow: '',
          zIndex: width < 1200 ? 5 : 0,
        }}
      >
        {texts?.map((item, index) => (
          <Button
            key={index}
            variant="contained"
            value={item.id}
            onClick={onClickButton}
            sx={{
              cursor: 'pointer',
              mx: '16px',
              maxHeight: '56px',
              minWidth: '56px',
              height: '56px',
              width: '56px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0,
              borderRadius: '100%',

              '@media (max-width: 1199px)': {
                opacity: 1,
              },
            }}
          >
            <VisibilityIcon
              sx={{
                fontSize: '32px',
                pointerEvents: 'none',
              }}
            />
          </Button>
        ))}
      </Box>
    </Box>
  )
}

ShowHover.defaultProps = {
  selected: -1,
}

export default ShowHover

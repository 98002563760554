/* eslint-disable no-unused-vars */

import React, {useEffect, useLayoutEffect, Fragment} from 'react'
import {useLocation} from 'react-router-dom'
import Marquee from 'react-fast-marquee'

// @mui components
import {useTheme} from '@mui/material/styles'
import {Box, Typography, Link as MuiLink} from '@mui/material'

// @emotion/css
import {css} from '@emotion/css'

// own components
import CarouselItemArrows from '../../components/Carousel/CarouselItemArrows'
import HeroContent from '../../components/HeroContent/HeroContent'
import ShowHover from '../../components/ShowHover/ShowHover'
import ShowGrid from '../../components/ShowGrid/ShowGrid'
import Hero from '../../layouts/Hero/Hero'

// layouts

// contexts
import {useRoute} from '../../context/RouterProvider'
import {useLanguage} from '../../context/LanguageProvider'
import ScrollSticky from '../../components/ScrollSticky/ScrollSticky'
import {useShowHoverContext} from '../../context/ShowHoverContext'

import googleplay from './../../assets/images/market apps/agoogle play sobre fondo negro.svg'
import appstore from './../../assets/images/market apps/aApp store sobre fondo negro.svg'

const Home = () => {
  const {setRouteState} = useRoute()
  const {languageState} = useLanguage()
  const location = useLocation()
  const {reset} = useShowHoverContext()

  const theme = useTheme()

  useEffect(() => {
    setRouteState({type: 'set', to: 0})
  }, [setRouteState])

  useLayoutEffect(() => {
    reset()
    window.scrollTo({
      top: 0,
    })
  }, [location, reset])

  return (
    <Fragment>
      <Hero src={languageState.texts.Home.Hero.src}>
        <HeroContent text={languageState.texts.Home.Hero} />
      </Hero>

      <Box
        sx={{backgroundColor: theme.palette.primary.main}}
        className="content-showhover-container"
      >
        <div>
          <div className="row">
            <div
              className="content-container text-center"
              style={{display: 'flex', flexDirection: 'column'}}
            >
              <Typography
                className="text-gap"
                variant="h2"
                sx={{
                  '@media (max-width: 1199px)': {
                    mx: 'auto',
                  },
                  color: theme.palette.text.alter,
                }}
              >
                {languageState.texts.Home.ShowHover.Title}
              </Typography>
              <Typography
                className="text-gap"
                variant="subtitle1"
                sx={{
                  textAlign: 'center',
                  '@media (max-width: 1199px)': {
                    alignSelf: 'center',
                    p: 0,
                  },
                  color: theme.palette.text.alter,
                }}
              >
                {languageState.texts.Home.ShowHover.TextBody}
              </Typography>
            </div>
          </div>
        </div>
        <ShowHover texts={languageState.texts.Home.ShowHover.Experiences} />
      </Box>
      <Box
        className="header-col"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Marquee gradient={false} speed="200">
          <Typography
            sx={{
              mx: 5,
              color: theme.palette.text.alter,
              fontSize: 'calc(56px + 124 * ((100vw - 320px)/1680))',
              fontFamily: 'Times New Roman,Times,serif',
            }}
          >
            {languageState.texts.Home.Marquee.Title}
          </Typography>
        </Marquee>
        <Typography
          variant="subtitle1"
          sx={{
            px: 2,
            color: theme.palette.text.alter,
            alignSelf: 'center',
            textAlign: 'center',
            '@media (max-width: 768px)': {},
          }}
        >
          {languageState.texts.Home.Marquee.TextBody}
        </Typography>
      </Box>

      <ScrollSticky />
      <CarouselItemArrows
        image={languageState.texts.Home.CarouselItemArrows.src}
        text={languageState.texts.Home.CarouselItemArrows}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {xs: 'row', md: 'column'},
            gap: {xs: '10px', md: '10px'},
            alignItems: {md: 'flex-start'},
          }}
        >
          <MuiLink
            id="play-store"
            target="_blank"
            underline="none"
            href="https://play.google.com/store/apps/details?id=com.inmersoft.trinidad"
            className={css({
              '@media (min-width: 577px)': {
                width: 'auto',
                height: '70px',
              },
            })}
          >
            <img
              loading="lazy"
              width={'100%'}
              height={'100%'}
              src={googleplay}
              alt="google-store"
            />
          </MuiLink>
          <MuiLink
            id="app-store"
            target="_blank"
            underline="none"
            href="https://play.google.com/store/apps/details?id=com.inmersoft.trinidad"
            className={css({
              '@media (min-width: 577px)': {
                width: 'auto',
                height: '70px',
              },
            })}>
            <img
              loading="lazy"
              width={'100%'}
              height={'100%'}
              src={appstore}
              alt="apple-store"
            />
          </MuiLink>
        </Box>
      </CarouselItemArrows>
      <ShowGrid />
    </Fragment>
  )
}

export default Home

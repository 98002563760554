/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState, useCallback} from 'react'

// react-router-dom
import {Link, useLocation} from 'react-router-dom'

// prop types
import PropTypes from 'prop-types'

// @mui components
import {useTheme} from "@mui/material/styles"
import {Drawer, IconButton, Typography, Box} from '@mui/material'

// @mui icons
import {Close as CloseIcon} from '@mui/icons-material'

// images
import logo from '../../assets/images/logos/logo-new.svg'

// own components

import Container from '../Container/Container'

// contexts
import {useLanguage} from '../../context/LanguageProvider'
import {useRoute} from '../../context/RouterProvider'
import useScreenSize from '../../hooks/useScreenSize'

const NavigationDrawer = React.memo(props => {
  const {
    visible,
    onClose,
    activeLink,
    toggleSecondaryDrawer,
    setMenuSecondary,
    setActiveLink,
    menu,
    weather,
  } = props

  const location = useLocation()

  const {setRouteState} = useRoute()
  const {languageState} = useLanguage()

  const theme = useTheme()
  const {width} = useScreenSize()

  const [visibleState, setVisibleState] = useState(false)

  useEffect(() => {
    setVisibleState(visible)
  }, [visible])

  const clickJtem = useCallback(
    e => {
      if (width <= 1200) {
        e.preventDefault()
        const {id} = e.target
        const justId = Number(id.substring(1))

        if (menu[justId].menu) {
          setMenuSecondary(menu[justId])
          toggleSecondaryDrawer(true)
          setActiveLink(languageState.texts.Navbar.Links[justId])
        }
      } else {
        const {id} = e.target
        setRouteState({type: 'jndex', to: Number(id.substring(1))})
        onClose(e)
      }
    },
    [
      width,
      languageState,
      menu,
      onClose,
      setActiveLink,
      setMenuSecondary,
      setRouteState,
      toggleSecondaryDrawer,
    ],
  )

  const drawerMouseEnter = useCallback(
    e => {
      if (visible) {
        const {id} = e.target
        const justId = Number(id.substring(1))
        const parsedMenu = menu.filter(item => item.label)

        if (parsedMenu.menu) {
          if (parsedMenu[justId].menu) {
            setMenuSecondary(parsedMenu[justId])
            toggleSecondaryDrawer(true)
          } else if (!parsedMenu[justId].menu) {
            toggleSecondaryDrawer(false)
          }
        } else {
          if (
            languageState.texts.Navbar.Links.find(
              link => link.id === activeLink.id,
            )
          ) {
            if (parsedMenu[justId]) {
              if (parsedMenu[justId].menu) {
                setMenuSecondary(parsedMenu[justId])
                toggleSecondaryDrawer(true)
              } else toggleSecondaryDrawer(false)
            } else if (!parsedMenu[justId]) {
              toggleSecondaryDrawer(false)
            }
          }
        }
      }
    },
    [
      menu,
      activeLink,
      languageState,
      setMenuSecondary,
      toggleSecondaryDrawer,
      visible,
    ],
  )

  useEffect(() => {
    if (width > 1200) onClose()
  }, [width])

  return (
    <Drawer
      sx={{background: `${theme.palette.primary.main}9c`, boxShadow: 'none'}}
      anchor="left"
      open={visibleState}
      onClose={onClose}
    >
      <Container
        direction="column"
        sx={{
          background: theme.palette.primary.light,
          width: '500px',
          minHeight: '100vh',
          '@media(max-width:1000px)': {
            width: '100vw',
          },
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '20px 20px 20px 3vw',
            py: '2vh',
            background: width < 1000 && theme.palette.primary.main,
          }}
          justify={'space-between'}
        >
          <Link id="home" to="/">
            <img
              src={logo}
              alt="Descubre Trinidad Logo"
              onClick={onClose}
              style={{
                display: 'flex',
                width: '190px',
                height: '60px',
              }}
            />
          </Link>

          <IconButton
            color="side"
            aria-label="close drawer"
            sx={{color: theme.palette.text.alter}}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Container>

        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{marginTop: '20px', width: '100%'}}>
            <Link
              id={activeLink.id}
              style={{
                textDecoration: 'none',
                color:
                  menu.route === location.pathname
                    ? theme.palette.primary.main
                    : theme.palette.text.main,
                display: width < 1000 && 'none',
              }}
              onClick={onClose}
              onMouseEnter={width > 1000 ? drawerMouseEnter : undefined}
              to={activeLink.route}
            >
              <Typography
                id={`b${activeLink.id}`}
                sx={{
                  textTransform: 'none',
                  marginTop: '10px',
                  marginBottom: '1rem',
                  paddingLeft: '3vw',
                  color: theme.palette.text.alter,
                  '&:hover': {},
                }}
                variant="body1"
              >
                {activeLink.label}
              </Typography>
            </Link>
            <Box sx={{}}>
              <Container direction="column">
                {menu
                  .filter(item => item.label)
                  .map((jtem, j) => (
                    <Link
                      id={`k${j}`}
                      key={jtem.id}
                      style={{
                        textDecoration: 'none',
                      }}
                      onClick={clickJtem}
                      to={width > 1000 && jtem.route}
                      onMouseEnter={width > 1000 ? drawerMouseEnter : undefined}
                    >
                      <Typography
                        id={`b${j}`}
                        sx={{
                          padding: '1rem',
                          px: '3vw',
                          fontWeight: 'bold',
                          fontSize: '1.25em',
                          color:
                            jtem.route === location.pathname
                              ? theme.palette.text.secondary
                              : theme.palette.text.alter,
                          transition: '0.35s all ease',
                          '&:hover': {
                            color:
                              jtem.route === location.pathname
                                ? theme.palette.text.secondary
                                : theme.palette.text.secondary,
                            backgroundColor: theme.palette.primary.main,
                            borderRight:
                              jtem.id === activeLink.id
                                ? '8px solid #ffee58'
                                : '',
                            px: '65px',
                          },
                        }}
                        variant="h5"
                      >
                        {jtem.label}
                      </Typography>
                    </Link>
                  ))}
              </Container>
            </Box>
          </Box>
          <Typography
            variant="body1"
            sx={{
              display: width > 600 ? 'none' : 'flex',
              alignItems: 'center',
              position: 'absolute',
              bottom: '10px',
              color: theme.palette.text.alter,
            }}
          >
            <img
              width="50"
              src={weather?.condition?.icon}
              srcSet={`${weather?.condition?.icon} 2x`}
              alt=""
              style={{
                filter:
                  'drop-shadow(0px 0px 0 grey) drop-shadow(0px 0px 0 grey)',
                display: width > 600 ? 'none' : 'flex',
              }}
            />
            {weather?.temp_c}°C
          </Typography>
        </Box>
      </Container>
    </Drawer>
  )
})

NavigationDrawer.displayName = 'NavigationDrawer'

NavigationDrawer.defaultProps = {
  setMenuSecondary: {},
}

NavigationDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleSecondaryDrawer: PropTypes.func.isRequired,
  setMenuSecondary: PropTypes.func,
}

export default NavigationDrawer

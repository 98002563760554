import config from '../config'

import {
  getCookie,
  createCookie,
  getUserLanguage,
} from 'some-javascript-utils/browser'

// auth
import {getAuth} from '../auth/auth'

export const sendReboundCookie = async () => {
  if (
    getCookie(config.acceptCookie) &&
    !getCookie(config.reboundCookie).length
  ) {
    try {
      const response = await fetch(
        // @ts-ignore
        `${config.serverUrl}/new-analytics/trigger/add`,
        {
          method: 'POST',
          body: JSON.stringify({
            event: 'rebounds',
            device: window.innerWidth > 900 ? 'pc' : 'mobile',
            app: 'webpage',
            lang: getUserLanguage(),
            url: document.URL,
            country: getCookie(config.countryCookie),
          }),
          headers: getAuth,
        },
      )
      createCookie(config.reboundCookie, 1, 'rebound')
      const data = await response.json()

      localStorage.setItem('rebound-cookie-id', data.data.id)
      return data
    } catch (err) {
      console.error(err)
    }
  }
}

/**
 * @returns
 */
export const sendVisitCookie = async () => {
  if (getCookie(config.acceptCookie) && !getCookie(config.visitCookie).length) {
    try {
      const response = await fetch(
        // @ts-ignore
        `${config.serverUrl}/new-analytics/trigger/add`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: localStorage.getItem('rebound-cookie-id'),
            event: 'visits',
            device: window.innerWidth > 900 ? 'pc' : 'mobile',
            app: 'webpage',
            lang: getUserLanguage(),
            url: document.URL,
            country: getCookie(config.countryCookie),
          }),
          headers: getAuth,
        },
      )
      createCookie(config.visitCookie, 1, '1')
      createCookie(config.reboundCookie, 1, 'visit')
      const data = await response.json()
      return data
    } catch (err) {
      console.error(err)
    }
  }
}

/**
 * @returns
 */
export const sendNewsletterCookie = async () => {
  if (getCookie(config.acceptCookie) && !getCookie(config.newsletterCookie).length) {
    try {
      const response = await fetch(
        // @ts-ignore
        `${config.serverUrl}new-analytics/trigger/add`,
        {
          method: 'POST',
          body: JSON.stringify({
            event: 'newsletter',
            device: window.innerWidth > 900 ? 'pc' : 'mobile',
            app: 'webpage',
            lang: getUserLanguage(),
            url: document.URL,
            country: getCookie(config.countryCookie),
          }),
          headers: getAuth,
        },
      )
      createCookie(config.newsletterCookie, 1, '1')
      const data = await response.json()
      return data
    } catch (err) {
      console.error(err)
    }
  }
}

import React, {memo, useMemo, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'

// @mui/material
import {Typography} from '@mui/material/'
import {useTheme} from '@mui/material/styles'

// @emotion/css
import {css} from '@emotion/css'

function SecondaryLink({jtem, j, onClick}) {
  const theme = useTheme()

  const location = useLocation()

  const [show, setShow] = useState(false)

  const container = useMemo(() => {
    return css({
      display: 'flex',
      padding: '0 20px',
      flexDirection: 'column',
      transition: 'all 500ms ease',
      opacity: show ? 1 : 0,
    })
  }, [show])

  const padding = useMemo(() => {
    return css({
      padding: '1rem',
      transition: 'all 500ms ease',
      transform: show ? 'translateY(0)' : 'translateY(20)',
    })
  }, [show])

  const link = useMemo(() => {
    return css({textDecoration: 'none', paddingTop: '10px'})
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, j * 100 + 200)
  }, [j])

  return (
    <div className={container}>
      <div className={padding}>
        <Link onClick={onClick} to={jtem.route} className={link}>
          <Typography
            id={`b${j}`}
            sx={{
              textTransform: 'none',
              /*   padding: "3rem", */

              fontWeight: 'bold',
              color:
                jtem.route === location.pathname
                  ? theme.palette.text.secondary
                  : theme.palette.text.alter,
              '&:hover': {
                color:
                  jtem.index === location.pathname
                    ? theme.palette.text.secondary
                    : theme.palette.text.secondary,

                transition: '200ms all ease',
              },
            }}
            variant="h5"
            onClick={onClick}
          >
            {jtem.label}
          </Typography>
        </Link>
      </div>
    </div>
  )
}

const SecondaryLinkMemo = memo(
  props => <SecondaryLink {...props} />,
  arePropsEqual,
)

SecondaryLinkMemo.displayName = 'SecondaryLink'

function arePropsEqual(oldProps, newProps) {
  return (
    oldProps.jtem === newProps.jtem &&
    oldProps.j === newProps.j &&
    oldProps.onClick === newProps.onClick
  )
}

export default SecondaryLinkMemo

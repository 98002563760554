import React, {Suspense} from 'react'
// prop types
import PropTypes from 'prop-types'

// react-router-dom
import {Outlet} from 'react-router-dom'

// @mui components
import {Box} from '@mui/material'

// own components
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import BigLoading from '../../components/Loading/BigLoading'

const View = props => {
  const {noFooter} = props
  return (
    <Box sx={{minHeight: '100vh'}}>
      <Navbar />
      <Suspense fallback={<BigLoading />}>
        <Box sx={{minHeight: '100vh'}}>
          <Outlet />
        </Box>
      </Suspense>
      {!noFooter ? <Footer /> : null}
    </Box>
  )
}

View.defaultProps = {
  noFooter: false,
}

View.propTypes = {
  noFooter: PropTypes.bool,
}

export default View
